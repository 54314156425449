import Vue from "vue";
import App from "./App.vue";

import store from "./store/store.js"
import router from "./router/router.js"

Vue.config.productionTip = false;

new Vue({
  store: store,
  router: router,
  render: h => h(App),
}).$mount("#app");
