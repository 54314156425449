<template>
  <div class="box">
    <div class="content">
      <table style="position: relative; bottom: 57px">
        <tr>
          <td>
            <p class="text" align="center">{{ ErrorMessage }}</p>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      ErrorMessage: "An error occurred while signing in. Please try again later."
    }
  },
};
</script>
