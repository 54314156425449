<template>
  <div class="error-container merge-error">
    <p class="title">{{ ErrorTitle }}</p>
    <div class="error-details">
      <p class="error-header">{{ errorText }}</p>
      <p class="error-text">{{ errorDetail }}</p>
    </div>
    <button class="submitButton" v-on:click="back">
      <p class="back-icon">{{ BackIcon }}</p>
      <p class="return-button-text">{{ ReturnButtonText }}</p>
    </button>
    <p class="error-support">{{ ErrorSupport }}</p>
    <p v-if="showPrimaryPuid()" class="puid">{{ PrimaryPuid }}</p>
    <p v-if="showSecondaryPuid()" class="puid">{{ SecondaryPuid }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      errorText: "",
      errorDetail: "",
    };
  },
  mounted() {
    this.errorText = this.$store.state.merging.errors.mergeError;
    this.errorDetail = this.$store.state.merging.errors.mergeErrorDetail;
  },
  computed: {
    PrimaryPuid() {
      return "Primary PUID: " + this.$store.state.merging.primaryKeyring?.queryResponse?.puid;
    },
    SecondaryPuid() {
      return "Secondary PUID: " + this.$store.state.merging.secondaryKeyring?.queryResponse?.puid;
    },
    ErrorTitle() {
      return this.$store.getters.translate("MergeErrorHeader");
    },
    ReturnButtonText() {
      return this.$store.getters.translate("MergeErrorReturn");
    },
    ErrorSupport() {
      return this.$store.getters.translate("MergeErrorSupport");
    },
    BackIcon() {
      return "◀";
    },
  },
  methods: {
    async back(e) {
      e.preventDefault();
      this.$store.commit("clearMergeErrors");
      this.$store.commit("clearMergeInfo");
      this.$router.push({ name: "mergeAccounts" });
    },
    showPrimaryPuid() {
      return !!this.$store.state.merging.primaryKeyring?.queryResponse?.puid;
    },
    showSecondaryPuid() {
      return !!this.$store.state.merging.secondaryKeyring?.queryResponse?.puid;
    },
  },
};
</script>
<style scoped>
.error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 6em;
  font-family: "Brook", Ariel;
  text-align: center;
  margin-bottom: 0.5em;
}

.error-details {
  width: 32%;
  font-size: 1.5em;
}

.error-header {
  color: #d63924;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
}
.error-text {
  text-align: center;
}
.submitButton {
  display: flex;
  height: 115px;
  width: 325px;
  font-size: 5em;
}
.return-button-text {
  margin-left: 25px;
  margin-bottom: 0;
}
.back-icon {
  margin-bottom: 0;
}
.error-support {
  margin-top: 4%;
}
.puid {
  font-size: 1.5em;
  margin-bottom: 0;
}
</style>
