<template>
  <nav
    class="p-2 mb-3 navbar navbar-expand navbar-dark"
    style="background-color: rgba(20, 20, 20, 0.5)"
  >
    <a class="navbar-brand" href="/"
      ><img src="../assets/AmongUsLogo.png" height="60" alt="Among Us"
    /></a>

    <ul class="navbar-nav ms-auto">
      <li class="nav-item dropdown">
        <a
          class="nav-link dropdown-bs-toggle"
          id="navbarDropdownMenuLink"
          role="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{ CurrentLanguage }}
        </a>
        <div
          class="dropdown-menu dropdown-menu-right text-center dropdown-multicol"
          aria-labelledby="navbarDropdownMenuLink"
        >
          <template v-for="[lang] in languageMap">
            <button
              :key="lang"
              class="dropdown-item"
              type="button"
              v-on:click="languageSelect(lang)"
            >
              {{ lang }}
            </button>
          </template>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      CurrentLanguage: "English",
      languageMap: {},
    };
  },
  mounted() {
    (this.languageMap = this.$store.getters.languageMap),
      (this.CurrentLanguage = this.$store.getters.getLanguageByCode(
        this.$store.state.user.language
      ));
  },
  methods: {
    languageSelect(lang) {
      this.CurrentLanguage = lang;
      this.$store.state.user.language = this.languageMap.get(lang);
      sessionStorage.setItem("language", this.languageMap.get(lang));
      location.reload();
    },
  },
};
</script>

<style scoped>
.navbar-nav > li > .dropdown-menu {
  background-color: #101010;
  top: 60px;
  right: -15px;
  left: unset;
  border-radius: 0rem;
}

.navbar-nav > li > .dropdown-menu > .dropdown-item {
  color: #ffffff;
  vertical-align: middle;
}

div.dropdown-multicol {
  width: 100vw;
}

button.dropdown-item {
  display: inline-block;
  width: 12%;
}

@media screen and (max-width: 450px) {
  .navbar-nav > li > .dropdown-menu {
    top: 54px;
    right: -17px;
    border-radius: 0rem;
  }
}
</style>
