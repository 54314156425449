<template>
  <div class="outer-container merge-success">
    <div class="header-info">
      <h1>{{ SuccessText }}</h1>
      <p class="success-header">
        {{ SuccessHeader }}
      </p>
      <p class="success-message">
        {{ SuccessMessage }}
      </p>
    </div>

    <div>
      <div class="resulting-platforms">
        <p class="puid">{{ Puid }}</p>
        <div v-for="(platform, key) in platforms" :key="key">
          <button
            class="accountButton"
            v-if="ShowPrimaryPlatform(key) || ShowSecondaryPlatform(key)"
            disabled
          >
            <img class="logo" :src="platform.logoSrc" alt="Logo" />

            <h3 class="buttonText">
              {{ platform.name }}
            </h3>
          </button>
        </div>
      </div>
    </div>
    <p class="restart-warning">
      {{ SuccessRestart }}
    </p>
    <div class="button-box">
      <button class="submitButton" v-on:click="BackToHome">
        {{ BackToHomeText }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      platforms: this.$store.getters.platformData,
    };
  },
  async mounted() {
    // now that we've redirected successfully, we can delete the cookie
    document.cookie =
      "REDIRECT=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;samesite=none;secure";
  },
  computed: {
    Puid() {
      return "PUID: " + this.$store.state.merging.primaryKeyring?.queryResponse?.puid;
    },
    SuccessText() {
      return this.$store.getters.translate("MergeSuccess");
    },
    SuccessHeader() {
      return this.$store.getters.translate("MergeSuccessHeader");
    },
    SuccessMessage() {
      return this.$store.getters.translate("MergeSuccessMessage");
    },
    BackToHomeText() {
      return this.$store.getters.translate("MergeSuccessHome");
    },
    SuccessRestart() {
      return this.$store.getters.translate("MergeSuccessRestart");
    },
    PrimaryPlatforms() {
      return this.$store.state.merging.primaryKeyring.queryResponse.platforms;
    },
    SecondaryPlatforms() {
      return this.$store.state.merging.secondaryKeyring.queryResponse.platforms;
    },
  },
  methods: {
    async BackToHome(e) {
      e.preventDefault();
      this.$store.commit("clearMergeInfo");
      this.$router.push({ name: "mergeAccounts" });
    },
    ShowPrimaryPlatform(platform) {
      return this.PrimaryPlatforms.includes(platform);
    },
    ShowSecondaryPlatform(platform) {
      return this.SecondaryPlatforms.includes(platform);
    },
  },
};
</script>
<style scoped>
template {
  height: 100%;
}

.success-header {
  font-weight: bold;
  margin-bottom: 0;
  font-size: 1.2em;
}

.success-message {
  font-size: 1.2em;
  line-height: 0.8em;
}

.outer-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.accountButton {
  width: 18em;
  height: 4.25em;
}

.buttonText {
  text-align: left;
  left: 11%;
  margin-bottom: 0;
  font-size: 2.35em;
}

.button-box {
  display: flex;
  left: 34%;
}

.submitButton {
  height: 115px;
  width: 325px;
  font-size: 5em;
}

.header-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
}

.header-info h1 {
  color: #00c96a;
  margin-bottom: 0.4em;
}

.attention-header {
  margin-bottom: 0;
}

.resulting-platforms {
  background-color: #000000;
  border: 4px solid #121212;
  border-radius: 10px;

  min-height: 250px;
  padding: 2em;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.restart-warning {
  color: #d63924;
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
  margin-top: 1em;
}

.puid {
  font-size: 1.5em;
  margin-bottom: 1%;
}

/* medium screens */
@media screen and (max-width: 1200px) {
}

/* small screens */
@media screen and (max-width: 500px) {
}
</style>
