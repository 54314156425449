<template>
  <div class="row d-flex justify-content-center text-center m-5 p-1">
    <h1 class="mb-5">{{ MergeAccountsText }}</h1>
    <h5 class="mb-3 text text-danger">
      <b>{{ MergingKWSHeader }}</b>
    </h5>
    <h5 class="mb-5 text">{{ MergingKWSMessage }}</h5>

    <div class="text text-danger mb-5" v-if="page.error">
      {{ MergeKWSError }}
    </div>

    <form v-on:submit="submit">
      <table>
        <tr><p class="puid">{{ Puid }}</p></tr>
        <tr>
          <input
            v-model="data.parent_email"
            type="email"
            placeholder="email@domain.com"
          />
        </tr>
        <tr>
          <td>
            <button class="backButton" v-on:click="back">
              {{ BackButtonText }}
            </button>
            <button type="submit" class="submitButton">
              {{ ContinueButtonText }}
            </button>
          </td>
        </tr>
      </table>
    </form>
  </div>
</template>

<script>
let ValidEmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
  data() {
    return {
      page: {
        invalid_email: false,
      },
      data: {
        parent_email: "",
      },
    };
  },
  computed: {
    Puid() {
      return "PUID: " + this.$store.state.merging.primaryKeyring?.queryResponse?.puid;
    },
    MergeAccountsText() {
      return this.$store.getters.translate("MergingMergeAccounts");
    },
    MergingKWSHeader() {
      return this.$store.getters.translate("MergeKWSHeader");
    },
    MergingKWSMessage() {
      return this.$store.getters.translate("MergeKWSMessage");
    },
    MergeKWSError() {
      return this.$store.getters.translate("MergeKWSError");
    },
    ContinueButtonText() {
      return this.$store.getters.translate("MergingContinue");
    },

    BackButtonText() {
      return "◀";
    },
  },
  methods: {
    async back(e) {
      e.preventDefault();

      this.$router.push({ name: "finishMergeAccounts" });
    },
    async submit(e) {
      e.preventDefault();

      if (!ValidEmailRegex.test(this.data.parent_email)) {
        this.page.invalid_email = true;
        return;
      }

      this.page.invalid_email = false;

      let res = await this.$store.dispatch("requestMissingPermissions", {
        data: {
          type: "request-permissions",
          attributes: {
            parent_email: this.data.parent_email,
            user_access_token: this.$store.getters.primary_access_token(),
          },
        },
      });

      if (res.errors) {
        this.$store.commit("saveMergingError", {
          error: res.errors[0].title,
          errorDetail: res.errors[0].detail,
        });
        this.$router.push({ name: "mergeError" });
        return;
      }

      if (this.$store.state.merging.willLoseFriends) {
        this.$router.push({name: "mergeFriendsAttention"});
        return;
      }
      this.$router.push({ name: "mergeSuccess" });
    },
  },
};
</script>

<style scoped>
input {
  width: 500px;
}

.submitButton {
  width: 200px;
  margin-right: 50px;
}

.puid {
  text-align: start;
  margin-left: 1%;
  margin-bottom: 1%;
  font-size: 1.4em;
}
</style>
