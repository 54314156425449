import translationfile from "./translations.tsv"


const errorDetailMap = {
  // TODO - there are duplicates between redeem and merge
  // redeem isn't actually being translated, maybe we could hardcode it for now?

  1000: "BackendErrorGeneric",

  // Redeem
  1300: "RedeemAPIErrorDoesntExist",

  3200: "RedeemAPIErrorRedeemed",
  3201: "RedeemAPIErrorAlreadyOwned",

  // Merge
  // - Nothing user can do:
  1100: "BackendErrorGeneric", // User context not found
  1002: "BackendErrorGeneric", // unmarshal
  1003: "BackendErrorGeneric", // validation
  1005: "BackendErrorGeneric", // read body
  1200: "BackendErrorGeneric", // database generic
  1207: "BackendErrorGeneric", // redis generic
  2200: "BackendErrorGeneric", // invalid email - no special copy bc we validate on frontend
  2202: "BackendErrorGeneric", // empty email - no special copy bc we validate on frontend
  3300: "BackendErrorGeneric", // EOS generic
  3311: "BackendErrorGeneric", // EOS access token
  3320: "BackendErrorGeneric", // EOS marshal
  3328: "BackendErrorGeneric", // KWS - error updating guardian email
  3508: "BackendErrorGeneric", // KWS - error getting permissions
  3518: "BackendErrorGeneric", // error getting user info from token

  // - custom error message or requires user action
  3509: "MergeErrorPermissions",          // one of the keyrings doesnt have linking permissions
  3517: "MergeErrorIncompatibleKeyrings", // keyrings are not compatible - they have the same platform account
  3523: "MergeErrorGuestAccountMerge",    // cannot merge a secondary keyring containing a guest account.
  3524: "MergeErrorActiveSanction",       // User tried to merge an account that had active sanctions

  // - user can't do anything, but indicates exactly where request failed
  3519: "MergeErrorUnlink",        // error unlinking platform account
  3520: "MergeErrorFinalPlatform", // error occurred while merging the final platform to the keyring
  3521: "MergeErrorFriends",       // error merging friends
  3522: "MergeErrorInventory",     // error occurred while merging inventories
};

const NO_LANG       = "";
const ENGLISH       = "en-US";
const FRENCH        = "fr-FR";
const ITALIAN       = "it-IT";
const GERMAN        = "de-DE";
const SPANISH       = "es-ES";
const LATAM         = "es-MX";
const DUTCH         = "nl-NL";
const RUSSIAN       = "ru-RU";
const PORTUGUESE_BR = "pt-BR";
const PORTUGUESE_EU = "pt-PT";
const JAPANESE      = "ja-JP";
const KOREAN        = "ko-KR";
const FILIPINO      = "fil";
const GAILGE         = "ga";
const S_CHINESE     = "zh-CN";
const T_CHINESE     = "zh-TW";

const INDONESIAN    = "id";
const THAI          = "th";
const TURKISH       = "tr";
const POLISH        = "pl";
const ARABIC        = "ar";
const MALAY         = "ms";

const storeDisplayNames = {
  "steam":     "Steam",
  "epic":      "Epic Games",
  "google":    "Google Play",
  "apple":     "Apple",
  "microsoft": "Xbox Live",
  "itchio":    "itch.io",
  "twitch": "Twitch",
}


export default class TranslationManager {
  translations = {}
  languageMap = new Map();


  constructor() {
    let translationsSplit = translationfile.split("\n")

    this.languageMap.set('English', 'en-US');
    this.languageMap.set('Français', 'fr-FR');
    this.languageMap.set('Italiano', 'it-IT');
    this.languageMap.set('Deutsch', 'de-DE');
    this.languageMap.set('Español', 'es-ES');
    this.languageMap.set('Español (Latinoamérica)', 'es-MX');
    this.languageMap.set('Nederlands', 'nl-NL');
    this.languageMap.set('Русский', 'ru-RU');
    this.languageMap.set('Português (Brasil)', 'pt-BR');
    this.languageMap.set('Português', 'pt-PT');
    this.languageMap.set('日本語', 'ja-JP');
    this.languageMap.set('한국어', 'ko-KR');
    this.languageMap.set('Bisaya', 'fil');
    this.languageMap.set('Gaeilge', 'ga');
    this.languageMap.set('简体中文', 'zh-CN');
    this.languageMap.set('繁體中文', 'zh-TW');
    this.languageMap.set('Indonesia', 'id');
    this.languageMap.set('ภาษาไทย', 'th');
    this.languageMap.set('Türkçe', 'tr');
    this.languageMap.set('Polski', 'pl');
    this.languageMap.set('Al Arabiya', 'ar');
    this.languageMap.set('Melayu', 'ms');

    translationsSplit.slice(1).forEach(line => {
      let tr = line.split("\t")
      this.translations[tr[0]] = {
        [NO_LANG]:       tr[5],
        [ENGLISH]:       tr[5],
        [FRENCH]:        tr[6],
        [ITALIAN]:       tr[7],
        [GERMAN]:        tr[8],
        [SPANISH]:       tr[9],
        [LATAM]:         tr[10],
        [DUTCH]:         tr[11],
        [RUSSIAN]:       tr[12],
        [PORTUGUESE_BR]: tr[13],
        [PORTUGUESE_EU]: tr[14],
        [JAPANESE]:      tr[15],
        [KOREAN]:        tr[16],
        [FILIPINO]:      tr[17],
        [GAILGE]:        tr[18],
        [S_CHINESE]:     tr[19],
        [T_CHINESE]:     tr[20],
        [INDONESIAN]:    tr[21],
        [THAI]:          tr[22],
        [TURKISH]:       tr[23],
        [POLISH]:        tr[24],
        [ARABIC]:        tr[25],
        [MALAY]:         tr[26]
      }
    })
  }

  translate(language, key) {
    return this.translations[key][language]
  }

  translateDetail(language, detail) {
    if (errorDetailMap[detail]) {
      return this.translate(language, errorDetailMap[detail])
    } else {
      // TODO - make this actually generic
      return this.translate(language, "RedeemGenericErrorMessage") + ": " + detail
    }
  }

  // Temporary solution for interpolating the platform into the one translation string with a variable.
  // If we have more of these we should build a better solution
  translateWithPlatform(language, key, platform) {
    let translation = this.translate(language, key);
    return translation.replace("{{StoreName}}", storeDisplayNames[platform]);
  }
}
