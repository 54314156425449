<template>
  <footer class="footer">
    <a href="mailto:support@innersloth.com" class="text text-uppercase">{{ SupportText }}</a>
    •
    <a href="https://www.innersloth.com/faq/" class="text text-uppercase">{{ FaqText }}</a>
    •
    <a href="https://www.innersloth.com/" class="text text-uppercase">INNERSLOTH</a>
  </footer>
</template>

<script>
export default {
  computed: {
    SupportText() {
      return this.$store.getters.translate("FooterSupport");
    },
    FaqText() {
      return this.$store.getters.translate("FooterFAQ");
    },
  },
};
</script>
<style scoped>
a:link {
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
}

a:hover {
  color: #5bbc2b;
}

div {
  white-space: nowrap;
  text-overflow: ellipsis;
}

footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  text-align: center;
  background-color: rgba(20, 20, 20, 0.9)
}

@media screen and (max-width: 600px) {
  a:link {
    text-decoration: none;
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>
