<template>
  <body>
    <div class="box">
      <div class="header" id="header"></div>

      <div class="content">
        <table style="position: relative">
          <tr>
            <td>
              <h1 class="text" id="header" align="center">
                {{ LinkingPinHeaderText }}
              </h1>
              <img
                class="storeLogo"
                :src="require(`../assets/Placeholder_${store}.png`)"
                :alt="`${store} Logo`"
              />
              <p class="text" align="center">{{ LinkingSuccessDetail }}</p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </body>
</template>

<script>
export default {
  computed: {
    LinkingPinHeaderText() {
      return this.$store.getters.translateWithPlatform("LinkingPinHeader");
    },
    LinkingSuccessDetail() {
      return this.$store.getters.translate("LinkingSuccessDetail");
    },
    store() {
      return this.$store.state.user.platform;
    },
  },
};
</script>
<style scoped>
td {
  text-align: center;
}
p {
  top: 20px;
}
</style>
