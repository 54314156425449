<template>
  <div class="outer-container finish-merge">
    <div class="header-info">
      <h1 class="merge-header">{{ MergeAccountsText }}</h1>
      <p class="friends-warning">
        {{ FriendRequestMessage }}
      </p>
    </div>
    <div class="platforms">
      <div>
        <h3 class="primary-header">{{ PrimaryAccountText }}</h3>
        <div class="primary-platforms">
          <p class="puid"> {{PrimaryPuid}} </p>
          <div v-for="(platform, key) in platforms" :key="key">
            <button
              class="accountButton"
              v-if="ShowPrimaryPlatform(key)"
              disabled
            >
              <img class="logo" :src="platform.logoSrc" alt="Logo" />

              <h3 class="buttonText">
                {{ platform.name }}
              </h3>
            </button>
          </div>
        </div>
      </div>

      <h3 class="mt-3"><b>+</b></h3>

      <div>
        <h3 class="primary-header">{{ ToBeMergedText }}</h3>
        <p class="account-warning">{{ MergingFinishAccountWarning }}</p>
        <div class="secondary-platforms">
          <p class="puid"> {{SecondaryPuid}} </p>
          <div v-for="(platform, key) in platforms" :key="key">
            <button class="accountButton" v-if="ShowSecondaryPlatform(key)">
              <img class="logo" :src="platform.logoSrc" alt="Logo" />

              <h3 class="buttonText">
                {{ platform.name }}
              </h3>
            </button>
          </div>
        </div>
      </div>

      <div class="break mt-3">
        <h3 class="text">▶▶</h3>
      </div>

      <div>
        <h3 class="primary-header">{{ MergeResultText }}</h3>
        <div class="resulting-platforms">
          <p class="puid"> {{PrimaryPuid}} </p>
          <div v-for="(platform, key) in platforms" :key="key">
            <button
              class="accountButton"
              v-if="ShowPrimaryPlatform(key) || ShowSecondaryPlatform(key)"
              disabled
            >
              <img class="logo" :src="platform.logoSrc" alt="Logo" />

              <h3 class="buttonText">
                {{ platform.name }}
              </h3>
            </button>
          </div>
        </div>
      </div>
    </div>

    <button class="submitButton" v-on:click="MergeAccount">
      {{ MergeText }}
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      platforms: this.$store.getters.platformData,
    };
  },
  computed: {
    showWin10Button() {
      return `${process.env.VUE_APP_MICROSOFT_SANDBOX_ID}` != "RETAIL";
    },
    PrimaryPuid() {
      return "PUID: " + this.$store.state.merging.primaryKeyring?.queryResponse?.puid;
    },
    SecondaryPuid() {
      return "PUID: " + this.$store.state.merging.secondaryKeyring?.queryResponse?.puid;
    },
    MergeAccountsText() {
      return this.$store.getters.translate("MergingMergeAccounts");
    },
    FriendRequestMessage() {
      return this.$store.getters.translate("MergingFinishFriendRequest");
    },
    PrimaryAccountText() {
      return this.$store.getters.translate("MergingFinishPrimary");
    },
    ToBeMergedText() {
      return this.$store.getters.translate("MergingToBeMerged");
    },
    MergeResultText() {
      return this.$store.getters.translate("MergingResult");
    },
    MergeText() {
      return this.$store.getters.translate("MergingMerge");
    },
    MergingFinishAccountWarning() {
      return this.$store.getters.translate("MergingFinishAccountWarning");
    },
    PrimaryPlatforms() {
      return this.$store.state.merging.primaryKeyring.queryResponse?.platforms;
    },
    SecondaryPlatforms() {
      return this.$store.state.merging.secondaryKeyring.queryResponse
        ?.platforms;
    },
    MergeButtonDisabled() {
      return !this.$store.getters.secondaryKeyringAllAuthed;
    },
  },

  methods: {
    ShowPrimaryPlatform(platform) {
      return this.PrimaryPlatforms?.includes(platform);
    },
    ShowSecondaryPlatform(platform) {
      return this.SecondaryPlatforms?.includes(platform);
    },

    async MergeAccount(e) {
      e.preventDefault();

      if (this.SecondaryPlatforms.length > 1) {
        this.$router.push({ name: "mergeAttentionUnlink" });
        return;
      }

      if (this.$store.state.merging.willLosePermissions) {
        this.$router.push({ name: "requestPermissions" });
        return;
      }

      if (this.$store.state.merging.willLoseFriends) {
        this.$router.push({ name: "mergeFriendsAttention" });
        return;
      }

      let res = await this.$store.dispatch("mergeAccount", null);
      if (res?.errors) {
        this.$store.commit("saveMergingError", {
          error: "Error Merging Accounts",
          errorDetail: res.errors[0].detail,
        });
        this.$router.push({ name: "mergeError" });
        return;
      }
      this.$router.push({ name: "mergeSuccess" });
    },
  },
};
</script>

<style scoped>
template {
  height: 100%;
}

.merge-header {
  text-align: center;
  margin-bottom: 0.1em;
}

.header-info {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin-bottom: 5em;
}

.break {
  display: flex;
  justify-content: center;
  width: 0%;
  margin: 0;
  padding: 0;
}

.outer-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.platforms {
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
}

.friends-warning {
  color: #d63924;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
}

.primary-platforms,
.secondary-platforms,
.resulting-platforms {
  background-color: #000000;
  border: 4px solid #121212;
  border-radius: 10px;

  width: 25vw;
  min-height: 250px;
  padding: 1em;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.secondary-platforms {
  background-color: #292929;
  border: 4px solid #121212;
}

.accountButton {
  width: 20vw;
  height: 65px;
}

.platform-to-merge {
  order: -2;
}

.primary-header {
  order: -3;
  font-family: "Brook", Ariel;
  font-size: 2.5em;
}

.linked-header {
  order: -1;
  font-family: "Brook", Ariel;
  font-size: 3em;
  margin-top: 0.5em;
}

#errorOutput {
  top: 15px;
}

td p {
  margin-bottom: 20px;
}

svg {
  margin-right: 5px;
}

#submitErrorOutput {
  position: absolute;
  top: 75vh;
  left: 50%;
  transform: translateX(-50%);
  width: 20vw;
  text-align: center;
  color: red;
  font-size: medium;
}

.account-warning {
  width: 25vw;
  color: #d63924;
  font-weight: bold;
}
.puid {
  font-size: 1.5em;
  text-align: center;
  margin-bottom: .2em;
}

/* medium screens */
@media screen and (max-width: 1200px) {
  .break {
    width: 100%;
    padding: 10px;
  }

  .primary-platforms,
  .secondary-platforms,
  .resulting-platforms {
    width: 40vw;
  }

  .accountButton {
    width: 30vw;
  }

  .resulting-platforms-account-button {
    width: 25vw;
  }
}

/* small screens */
@media screen and (max-width: 500px) {
  body {
    background: #020916;
    background-image: none;
  }

  #submitErrorOutput {
    top: 81vh;
    width: 90vw;
  }

  .break {
    padding: 0px;
  }

  .primary-header,
  .linked-header {
    font-size: 2.5em;
  }

  .primary-platforms,
  .secondary-platforms,
  .resulting-platforms {
    width: 66vw;
    min-height: 100px;
  }

  .platforms {
    flex-direction: column;
  }

  .accountButton {
    width: 50vw;
    height: 50px;
    font-size: 0.75em;
  }
}
</style>
