<template>
  <table class="merge-home" cellpadding="0" cellspacing="0">
    <tr>
      <td>
        <h1 class="merge-header" align="center">{{ MergingAccountHeader }}</h1>
      </td>
    </tr>
    <tr>
      <td>
        <p class="restart-warning">
          {{ RestartWarning }}
        </p>
      </td>
    </tr>

    <tr>
      <td>
        <div id="buttonRow">
          <button class="accountButton" id="steambtn" v-on:click="steamClick">
            <img
              class="logo"
              src="../assets/Placeholder_steam.png"
              alt="Steam Logo"
            />
            <h1 class="buttonText" align="center">
              {{ SteamButton }}
            </h1>
          </button>

          <button class="accountButton" id="epicbtn" v-on:click="epicClick">
            <img
              class="logo"
              src="../assets/Placeholder_epic.png"
              alt="EGS Logo"
            />
            <h1 class="buttonText" align="center">
              {{ EpicButton }}
            </h1>
          </button>

          <button class="accountButton" id="googlebtn" v-on:click="googleClick">
            <img
              class="logo"
              src="../assets/Placeholder_google.png"
              alt="Google Play Logo"
            />
            <h1 class="buttonText" align="center">
              {{ GoogleButton }}
            </h1>
          </button>

          <button class="accountButton" id="applebtn" v-on:click="appleClick">
            <img
              class="logo"
              src="../assets/Placeholder_apple.png"
              alt="Apple Logo"
            />
            <h1 class="buttonText" align="center">
              {{ AppleButton }}
            </h1>
          </button>

          <button
            class="accountButton"
            id="microsoftbtn"
            v-on:click="microsoftClick"
            v-if="!showWin10Button"
          >
            <img
              class="logo"
              src="../assets/Placeholder_microsoft.png"
              alt="Xbox Logo"
            />
            <h1 class="buttonText" align="center">
              {{ MicrosoftButton }}
            </h1>
          </button>

          <!--This one is only used for staging because we need to differentiate Win10 from Xbox for sandbox reasons-->
          <button
            class="accountButton"
            id="win10btn"
            v-on:click="win10Click"
            v-if="showWin10Button"
          >
            <img
              class="logo"
              src="../assets/Placeholder_microsoft.png"
              alt="Xbox Logo"
            />
            <h1 class="buttonText" align="center">
              {{ Win10Button }}
            </h1>
          </button>

          <button class="accountButton" id="itchiobtn" v-on:click="itchioClick">
            <img
              class="logo"
              src="../assets/Placeholder_itchio.png"
              alt="Itch.io Logo"
            />
            <h1 class="buttonText" align="center">
              {{ ItchioButton }}
            </h1>
          </button>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  mounted() {
    const currLang = this.$store.state.user.language;
    this.$store.commit("clearMergeInfo");
    this.$store.state.user.language = currLang;
    sessionStorage.setItem("language", currLang);
  },
  computed: {
    showWin10Button() {
      return `${process.env.VUE_APP_MICROSOFT_SANDBOX_ID}` != "RETAIL";
    },

    MergingAccountHeader() {
      return this.$store.getters.translate("MergingAccountManagement");
    },
     RestartWarning() {
      return this.$store.getters.translate("MergingHomeGameRunning");
    },
    MergingHomeSwitchPsnWarning() {
      return this.$store.getters.translate("MergingHomeSwitchPsnWarning");
    },
    SteamButton() {
      return this.$store.getters.translate("MergingHomeSteamButton");
    },
    EpicButton() {
      return this.$store.getters.translate("MergingHomeEpicButton");
    },
    GoogleButton() {
      return this.$store.getters.translate("MergingHomeGoogleButton");
    },
    AppleButton() {
      return this.$store.getters.translate("MergingHomeAppleButton");
    },
    MicrosoftButton() {
      return this.$store.getters.translate("MergingHomeMicrosoftButton");
    },
    Win10Button() {
      return this.$store.getters.translate("MergingHomeWin10Button");
    },
    ItchioButton() {
      return this.$store.getters.translate("MergingHomeItchioButton");
    },
  },

  methods: {
    steamClick() {
      this.redirect(
        `https://steamcommunity.com/oauth/login?response_type=token&client_id=${process.env.VUE_APP_STEAM_CLIENT_ID}`
      );
    },
    epicClick() {
      this.redirect(
        `https://www.epicgames.com/id/authorize?client_id=${process.env.VUE_APP_EPIC_CLIENT_ID}&response_type=code&scope=basic_profile&redirect_uri=${process.env.VUE_APP_SERVICE_URL}/epic`
      );
    },
    googleClick() {
      this.redirect(
        `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.VUE_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_SERVICE_URL}/google&response_type=code&scope=openid profile`
      );
    },
    appleClick() {
      this.redirect(
        `https://appleid.apple.com/auth/authorize?client_id=${process.env.VUE_APP_APPLE_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_SERVICE_URL}/apple&response_type=code id_token&scope=name email&response_mode=form_post`
      );
    },
    microsoftClick() {
      document.cookie = `microsoft_sandbox=${process.env.VUE_APP_MICROSOFT_SANDBOX_ID};path=/;samesite=none;secure`;
      this.redirect(
        `https://login.live.com/oauth20_authorize.srf?client_id=${process.env.VUE_APP_MICROSOFT_CLIENT_ID}&response_type=code&redirect_uri=${process.env.VUE_APP_SERVICE_URL}/microsoft&scope=XboxLive.signin`
      );
    },
    win10Click() {
      document.cookie = `microsoft_sandbox=${process.env.VUE_APP_WIN10_SANDBOX_ID};path=/;samesite=none;secure`;
      this.redirect(
        `https://login.live.com/oauth20_authorize.srf?client_id=${process.env.VUE_APP_MICROSOFT_CLIENT_ID}&response_type=code&redirect_uri=${process.env.VUE_APP_SERVICE_URL}/microsoft&scope=XboxLive.signin`
      );
    },
    itchioClick() {
      this.redirect(
        `https://itch.io/user/oauth?client_id=${process.env.VUE_APP_ITCHIO_CLIENT_ID}&scope=profile:me&response_type=token&redirect_uri=${process.env.VUE_APP_SERVICE_URL}/itchio`
      );
    },

    redirect(url) {
      document.cookie = `REDIRECT=merge;max-age=600;path=/;samesite=none;secure`; // expires after 10 min
      location.href = url;
    },
  },
};
</script>

<style scoped>
.merge-header {
  margin-bottom: 0;
}

input {
  background-image: url("../assets/CodeInputField.png");
  background-size: 100% 100%;
  background-color: transparent;
  border: none;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  height: 50px;
  bottom: 0vh;
  text-align: center;
  font-family: Arial, sans-serif;
  color: #ffffff;
  font-size: x-large;
}

input:hover {
  background-image: url("../assets/CodeInputFieldHover.png");
}

input:focus {
  background-image: url("../assets/CodeInputFieldHover.png");
  outline: none !important;
}

input:invalid {
  background-image: url("../assets/CodeInputFieldInvalid.png");
  box-shadow: none;
}

#errorOutput {
  top: 15px;
}

td p {
  margin-bottom: 20px;
}

svg {
  margin-right: 5px;
}

.restart-warning {
  color: #d63924;
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 3em;
}

#submitErrorOutput {
  position: absolute;
  top: 75vh;
  left: 50%;
  transform: translateX(-50%);
  width: 20vw;
  text-align: center;
  color: red;
  font-size: medium;
}

/* If the screen size is 450px wide or less*/
@media screen and (max-width: 450px) {
  #submitErrorOutput {
    top: 81vh;
    width: 90vw;
  }
}

/* disables the arrows on the number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* disables arrows on the number input for firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
