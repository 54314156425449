import Vue from "vue"
import VueRouter from "vue-router"

import ThirdPartyAuthRedeem from "../components/ThirdPartyAuthRedeem.vue"
import FinishAuth from "../components/FinishAuth.vue"
import RedeemCode from "../components/RedeemCode.vue"
import MergeAccounts from "../components/ThirdPartyAuthMerging.vue"
import MergeSignIn from "../components/MergeSignIn.vue"
import FinishMergeAccounts from "../components/FinishMergeAccounts.vue"
import KWSRequestPermissions from "../components/KWSRequestPermissions.vue"
import ErrorView from "../components/ErrorView.vue"
import MergeError from "../components/MergeError.vue"
import MergeAttentionUnlink from "../components/MergeAttentionUnlink.vue"
import MergeSuccess from "../components/MergeSuccess.vue"
import MergeFriendsAttention from "../components/MergeFriendsAttention.vue"

// TODO: AU-3341 Twitch Drops
// import TwitchDrops from "../components/TwitchDrops.vue"
import PinEntry from "../components/PinEntry.vue"
import Success from "../components/Success.vue"

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== "NavigationDuplicated") throw err
  });
}

Vue.use(VueRouter)

var router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "mergeAccounts",
      component: MergeAccounts,
    },
    {
      path: "/finish-auth",
      name: "finishAuth",
      component: FinishAuth,
    },
    {
      path: "/pin",
      name: "pinEntry",
      component: PinEntry,
    },
    {
      path: "/success",
      name: "success",
      component: Success,
    },
    {
      path: "/redeem/auth",
      name: "redeemAuth",
      component: ThirdPartyAuthRedeem,
    },
    {
      path: "/error",
      name: "error",
      component: ErrorView,
    },
    {
      path: "/merge-error",
      name: "mergeError",
      component: MergeError,
    },
    {
      path: "/redeem",
      name: "redeem",
      component: RedeemCode,
    },
    {
      path: "/merge-sign-in",
      name: "mergeSignIn",
      component: MergeSignIn,
    },
    {
      path: "/merge-attention",
      name: "mergeAttentionUnlink",
      component: MergeAttentionUnlink,
    },
    {
      path: "/finish-merge",
      name: "finishMergeAccounts",
      component: FinishMergeAccounts,
    },
    // TODO: AU-3341 Twitch Drops
    // {
    //   path: "/twitch-drops",
    //   name: "twitchAuth",
    //   component: TwitchDrops,
    // },

    {
      path: "/request-permissions",
      name: "requestPermissions",
      component: KWSRequestPermissions,
    },
    {
      path: "/merge-friends-attention",
      name: "mergeFriendsAttention",
      component: MergeFriendsAttention,
    },
    {
      path: "/merge-success",
      name: "mergeSuccess",
      component: MergeSuccess,
    },
  ],
})

export default router;
