import Vue from "vue";
import Vuex from "vuex";

import TranslationManager from "../translations/translations";

Vue.use(Vuex);

// identityProviderId that is returned from the /me keyring call
var mergePlatforms = {
  steam: "steam",
  epicgames: "epicgames",
  google: "google",
  apple: "apple",
  xbl: "xbl",
  itchio: "itchio",
};

var accountsServiceURL = process.env.VUE_APP_SERVICE_URL;
var backendServiceURL = process.env.VUE_APP_BACKEND_URL;
console.log(
  `Accounts URL: ${accountsServiceURL}\nBackend URL: ${backendServiceURL}`
);

async function getEosTokens(data) {
  var url = `${accountsServiceURL}/eos-auth?store=${data.store}&token=${data.token}`;
  if (data.store == "apple") {
    url += `&name=${data.name}`;
  } else if (data.store == "microsoft") {
    url += `&userhash=${data.hash}`;
  }

  var res = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
    },
  });

  return res;
}

// used to get/set the query response
const PRIMARY_KEYRING_QUERY_KEY = "primary_keyring_query";
const SECONDARY_KEYRING_QUERY_KEY = "secondary_keyring_query";

// used to specify merge access token keys in storage
const PRIMARY_ACCESS_TOKEN_PREFIX = "primary_access_token_";
const SECONDARY_ACCESS_TOKEN_PREFIX = "secondary_access_token_";

const WILL_LOSE_PERMISSIONS_KEY = "will_lose_permissions";
const WILL_LOSE_FRIENDS_KEY = "will_lose_friends";

export default new Vuex.Store({
  state: {
    translations: new TranslationManager(),

    user: {
      language: sessionStorage.getItem("language"),
      platform: sessionStorage.getItem("platform"),
      id_token: sessionStorage.getItem("id_token"),
      token: sessionStorage.getItem("token"),
      twitch_token: sessionStorage.getItem("twitch_token"),
    },
    merging: {
      willLosePermissions: JSON.parse(
        sessionStorage.getItem(WILL_LOSE_PERMISSIONS_KEY)
      ),
      willLoseFriends: JSON.parse(
        sessionStorage.getItem(WILL_LOSE_FRIENDS_KEY)
      ),

      primaryKeyring: {
        queryResponse: JSON.parse(
          sessionStorage.getItem(PRIMARY_KEYRING_QUERY_KEY)
        ),
        access_tokens: {
          [mergePlatforms.steam]: sessionStorage.getItem(
            PRIMARY_ACCESS_TOKEN_PREFIX + mergePlatforms.steam
          ),
          [mergePlatforms.epicgames]: sessionStorage.getItem(
            PRIMARY_ACCESS_TOKEN_PREFIX + mergePlatforms.epicgames
          ),
          [mergePlatforms.google]: sessionStorage.getItem(
            PRIMARY_ACCESS_TOKEN_PREFIX + mergePlatforms.google
          ),
          [mergePlatforms.apple]: sessionStorage.getItem(
            PRIMARY_ACCESS_TOKEN_PREFIX + mergePlatforms.apple
          ),
          [mergePlatforms.xbl]: sessionStorage.getItem(
            PRIMARY_ACCESS_TOKEN_PREFIX + mergePlatforms.xbl
          ),
          [mergePlatforms.itchio]: sessionStorage.getItem(
            PRIMARY_ACCESS_TOKEN_PREFIX + mergePlatforms.itchio
          ),
        },
      },

      secondaryKeyring: {
        queryResponse: JSON.parse(
          sessionStorage.getItem(SECONDARY_KEYRING_QUERY_KEY)
        ),
        access_tokens: {
          [mergePlatforms.steam]: sessionStorage.getItem(
            SECONDARY_ACCESS_TOKEN_PREFIX + mergePlatforms.steam
          ),
          [mergePlatforms.epicgames]: sessionStorage.getItem(
            SECONDARY_ACCESS_TOKEN_PREFIX + mergePlatforms.epicgames
          ),
          [mergePlatforms.google]: sessionStorage.getItem(
            SECONDARY_ACCESS_TOKEN_PREFIX + mergePlatforms.google
          ),
          [mergePlatforms.apple]: sessionStorage.getItem(
            SECONDARY_ACCESS_TOKEN_PREFIX + mergePlatforms.apple
          ),
          [mergePlatforms.xbl]: sessionStorage.getItem(
            SECONDARY_ACCESS_TOKEN_PREFIX + mergePlatforms.xbl
          ),
          [mergePlatforms.itchio]: sessionStorage.getItem(
            SECONDARY_ACCESS_TOKEN_PREFIX + mergePlatforms.itchio
          ),
        },
      },
      primaryPlatform: sessionStorage.getItem("primary_platform"),
      primaryIdToken: sessionStorage.getItem("primary_id_token"),
      primaryPlatformToken: sessionStorage.getItem("primary_platform_token"),
      primaryUserName: sessionStorage.getItem("primary_user_name"),
      primaryHash: sessionStorage.getItem("primary_hash"),
      secondaryPlatform: sessionStorage.getItem("secondary_platform"),
      secondaryIdToken: sessionStorage.getItem("secondary_id_token"),
      secondaryPlatformToken: sessionStorage.getItem(
        "secondary_platform_token"
      ),
      secondaryUserName: sessionStorage.getItem("secondary_user_name"),
      secondaryHash: sessionStorage.getItem("secondary_hash"),
      errors: {
        mergeError: sessionStorage.getItem("mergeError"),
        mergeErrorDetail: sessionStorage.getItem("mergeErrorDetail"),
      },
    },
  },

  getters: {
    isAuthed: (state) => {
      return state.user.id_token !== null && state.user.id_token !== undefined;
    },
    translate: (state) => (key) => {
      return state.translations.translate(state.user.language, key);
    },
    translateDetail: (state) => (detail) => {
      return state.translations.translateDetail(state.user.language, detail);
    },
    translateWithPlatform: (state) => (key) => {
      return state.translations.translateWithPlatform(
        state.user.language,
        key,
        state.user.platform
      );
    },
    languageMap: (state) => {
      return state.translations.languageMap;
    },
    getLanguageByCode: (state) => (code) => {
      for (let [key, value] of state.translations.languageMap.entries()) {
        if (value === code) {
          return key;
        }
      }
      return "";
    },

    primary_access_token: (state) => {
      return state.merging.primaryKeyring.access_tokens[
        state.merging.primaryPlatform
      ];
    },

    primaryKeyringAllAuthed: (state) => {
      return state.merging.primaryKeyring.queryResponse?.platforms.every(
        (platform) => {
          return (
            state.merging.primaryKeyring.access_tokens[platform.i] !== null &&
            state.merging.primaryKeyring.access_tokens[platform.i] !== undefined
          );
        }
      );
    },
    primaryKeyringAuthed: (state) => (platform) => {
      return state.merging.primaryKeyring.access_tokens[platform];
    },

    secondaryKeyringAllAuthed: (state) => {
      return state.merging.secondaryKeyring.queryResponse?.platforms.every(
        (platform) => {
          return (
            state.merging.secondaryKeyring.access_tokens[
              platform.identityProviderId
            ] !== null &&
            state.merging.secondaryKeyring.access_tokens[
              platform.identityProviderId
            ] !== undefined
          );
        }
      );
    },
    secondaryKeyringAuthed: (state) => (platform) => {
      return state.merging.secondaryKeyring.access_tokens[platform];
    },
    platformData: (state) => {
      let platformData = {
        steam: {
          name: state.translations.translate(
            state.user.language,
            "SteamPlatform"
          ),
          logoSrc: require("../assets/Placeholder_steam.png"),
          url: `https://steamcommunity.com/oauth/login?response_type=token&client_id=${process.env.VUE_APP_STEAM_CLIENT_ID}`,
          platformKey: "steam",
        },
        epicgames: {
          name: state.translations.translate(
            state.user.language,
            "EpicPlatform"
          ),
          logoSrc: require("../assets/Placeholder_epic.png"),
          url: `https://www.epicgames.com/id/authorize?client_id=${process.env.VUE_APP_EPIC_CLIENT_ID}&response_type=code&scope=basic_profile&redirect_uri=${process.env.VUE_APP_SERVICE_URL}/epic`,
          platformKey: "epicgames",
        },
        google: {
          name: state.translations.translate(
            state.user.language,
            "GooglePlatform"
          ),
          logoSrc: require("../assets/Placeholder_google.png"),
          url: `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.VUE_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_SERVICE_URL}/google&response_type=code&scope=openid profile`,
          platformKey: "google",
        },
        apple: {
          name: state.translations.translate(
            state.user.language,
            "ApplePlatform"
          ),
          logoSrc: require("../assets/Placeholder_apple.png"),
          url: `https://appleid.apple.com/auth/authorize?client_id=${process.env.VUE_APP_APPLE_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_SERVICE_URL}/apple&response_type=code id_token&scope=name email&response_mode=form_post`,
          platformKey: "apple",
        },
        xbl: {
          name: state.translations.translate(
            state.user.language,
            "XboxPlatform"
          ),
          logoSrc: require("../assets/Placeholder_microsoft.png"),
          cookie: `microsoft_sandbox=${process.env.VUE_APP_MICROSOFT_SANDBOX_ID};path=/;samesite=none;secure`,
          url: `https://login.live.com/oauth20_authorize.srf?client_id=${process.env.VUE_APP_MICROSOFT_CLIENT_ID}&response_type=code&redirect_uri=${process.env.VUE_APP_SERVICE_URL}/microsoft&scope=XboxLive.signin`,
          platformKey: "xbl",
        },
        itchio: {
          name: state.translations.translate(
            state.user.language,
            "ItchioPlatform"
          ),
          logoSrc: require("../assets/Placeholder_itchio.png"),
          url: `https://itch.io/user/oauth?client_id=${process.env.VUE_APP_ITCHIO_CLIENT_ID}&scope=profile:me&response_type=token&redirect_uri=${process.env.VUE_APP_SERVICE_URL}/itchio`,
          platformKey: "itchio",
        },
      };
      // We need the windows 10 sandbox url for staging
      if (process.env.VUE_APP_MICROSOFT_SANDBOX_ID != "RETAIL") {
        platformData["xbl"] = {
          name: state.translations.translate(
            state.user.language,
            "WindowsPlatform"
          ),
          logoSrc: require("../assets/Placeholder_microsoft.png"),
          cookie: `microsoft_sandbox=${process.env.VUE_APP_WIN10_SANDBOX_ID};path=/;samesite=none;secure`,
          url: `https://login.live.com/oauth20_authorize.srf?client_id=${process.env.VUE_APP_MICROSOFT_CLIENT_ID}&response_type=code&redirect_uri=${process.env.VUE_APP_SERVICE_URL}/microsoft&scope=XboxLive.signin`,
          platformKey: "xbl",
        };
      }
      return platformData;
    },
  },

  mutations: {
    clearToken: (state) => {
      state.user.token = null;
      state.user.id_token = null;
      state.user.twitch_token = null;
    },
    clearMergeInfo: (state) => {
      state.merging.willLosePermissions = null;
      state.merging.willLoseFriends = null;
      state.merging.primaryKeyring.queryResponse = null;
      state.merging.primaryKeyring.access_tokens = {};
      state.merging.secondaryKeyring.queryResponse = null;
      state.merging.secondaryKeyring.access_tokens = {};
      state.merging.primaryIdToken = null;
      state.merging.primaryPlatform = null;
      state.merging.primaryPlatformToken = null;
      state.merging.primaryUserName = null;
      state.merging.primaryHash = null;
      state.merging.secondaryPlatform = null;
      state.merging.secondaryIdToken = null;
      state.merging.secondaryPlatformToken = null;
      state.merging.secondaryUserName = null;
      state.merging.secondaryHash = null;
      state.merging.errors = {};
      sessionStorage.clear();
    },
    clearMergeErrors: (state) => {
      state.merging.errors = {};
    },
    // TODO DO WE NEED TO CLEAR MERGING TOKENS ETC?
    addPrimaryMergeAccessToken: (state, payload) => {
      sessionStorage.setItem(
        PRIMARY_ACCESS_TOKEN_PREFIX + payload.platform,
        payload.token
      );
      state.merging.primaryKeyring.access_tokens[payload.platform] =
        payload.token;
    },
    savePrimaryKeyringQueryResults: (state, payload) => {
      sessionStorage.setItem(
        PRIMARY_KEYRING_QUERY_KEY,
        JSON.stringify(payload)
      );
      state.merging.primaryKeyring.queryResponse = payload;
    },

    addSecondaryMergeAccessToken: (state, payload) => {
      sessionStorage.setItem(
        SECONDARY_ACCESS_TOKEN_PREFIX + payload.platform,
        payload.token
      );
      state.merging.secondaryKeyring.access_tokens[payload.platform] =
        payload.token;
    },
    savePrimaryMergeDetails: (state, payload) => {
      sessionStorage.setItem("primary_platform", payload.platform);
      sessionStorage.setItem("primary_id_token", payload.id_token);
      sessionStorage.setItem("primary_platform_token", payload.platform_token);
      sessionStorage.setItem("secondary_user_name", payload.name);
      sessionStorage.setItem("secondary_hash", payload.hash);

      state.merging.primaryIdToken = payload.id_token;
      state.merging.primaryPlatform = payload.platform;
      state.merging.secondaryPlatformToken = payload.platform_token;
      state.merging.secondaryUserName = payload.name;
      state.merging.secondaryHash = payload.hash;
    },
    saveSecondaryMergeDetails: (state, payload) => {
      sessionStorage.setItem("secondary_platform", payload.platform);
      sessionStorage.setItem("secondary_id_token", payload.id_token);
      sessionStorage.setItem(
        "secondary_platform_token",
        payload.platform_token
      );
      sessionStorage.setItem("secondary_user_name", payload.name);
      sessionStorage.setItem("secondary_hash", payload.hash);

      state.merging.secondaryPlatform = payload.platform;
      state.merging.secondaryIdToken = payload.id_token;
      state.merging.secondaryPlatformToken = payload.platform_token;
      state.merging.secondaryUserName = payload.name;
      state.merging.secondaryHash = payload.hash;
    },
    saveSecondaryKeyringQueryResults: (state, payload) => {
      sessionStorage.setItem(
        SECONDARY_KEYRING_QUERY_KEY,
        JSON.stringify(payload)
      );
      state.merging.secondaryKeyring.queryResponse = payload;

      state.merging.willLosePermissions = payload.will_lose_permissions;
      sessionStorage.setItem(
        WILL_LOSE_PERMISSIONS_KEY,
        payload.will_lose_permissions
      );

      state.merging.willLoseFriends = payload.will_lose_friends;
      sessionStorage.setItem(WILL_LOSE_FRIENDS_KEY, payload.will_lose_friends);
    },
    saveMergingError: (state, payload) => {
      sessionStorage.setItem("mergeError", payload.error);
      state.merging.errors.mergeError = payload.error;
      sessionStorage.setItem("mergeErrorDetail", payload.errorDetail);
      state.merging.errors.mergeErrorDetail = payload.errorDetail;
    },

    unlinkPrimaryPlatform: (state, platform) => {
      sessionStorage.removeItem(PRIMARY_ACCESS_TOKEN_PREFIX + platform);
      state.merging.primaryKeyring.access_tokens[platform] = null;

      let newPlatformList =
        state.merging.primaryKeyring.queryResponse.platforms.filter(
          (p) => p != platform
        );
      state.merging.primaryKeyring.queryResponse.platforms = newPlatformList;
      sessionStorage.setItem(
        PRIMARY_KEYRING_QUERY_KEY,
        JSON.stringify(state.merging.primaryKeyring.queryResponse)
      );

      console.log("unlinked", platform, "from primary keyring");
    },
    unlinkSecondaryPlatform: (state, platform) => {
      sessionStorage.removeItem(SECONDARY_ACCESS_TOKEN_PREFIX + platform);
      state.merging.secondaryKeyring.access_tokens[platform] = null;

      let newPlatformList =
        state.merging.secondaryKeyring.queryResponse.platforms.filter(
          (p) => p != platform
        );
      state.merging.secondaryKeyring.queryResponse.platforms = newPlatformList;
      sessionStorage.setItem(
        SECONDARY_KEYRING_QUERY_KEY,
        JSON.stringify(state.merging.secondaryKeyring.queryResponse)
      );
      console.log("unlinked", platform, "from secondary keyring");
    },

    swapPrimaryAndSecondary: (state) => {
      let primaryTokens = state.merging.primaryKeyring.access_tokens;
      let secondaryTokens = state.merging.secondaryKeyring.access_tokens;
      let primary = state.merging.primaryKeyring;
      let secondary = state.merging.secondaryKeyring;
      state.merging.primaryKeyring = secondary;
      state.merging.secondaryKeyring = primary;

      let primaryQuery = sessionStorage.getItem(PRIMARY_KEYRING_QUERY_KEY);
      let secondaryQuery = sessionStorage.getItem(SECONDARY_KEYRING_QUERY_KEY);
      sessionStorage.setItem(PRIMARY_KEYRING_QUERY_KEY, secondaryQuery);
      sessionStorage.setItem(SECONDARY_KEYRING_QUERY_KEY, primaryQuery);

      let primaryPlatform = sessionStorage.getItem("primary_platform");
      let secondaryPlatform = sessionStorage.getItem("secondary_platform");
      state.merging.primaryPlatform = secondaryPlatform;
      state.merging.secondaryPlatform = primaryPlatform;
      sessionStorage.setItem("primary_platform", secondaryPlatform);
      sessionStorage.setItem("secondary_platform", primaryPlatform);

      let primaryIdToken = sessionStorage.getItem("primary_id_token");
      let secondaryIdToken = sessionStorage.getItem("secondary_id_token");
      state.merging.primaryIdToken = secondaryIdToken;
      state.merging.secondaryIdToken = primaryIdToken;
      sessionStorage.setItem("primary_id_token", secondaryIdToken);
      sessionStorage.setItem("secondary_id_token", primaryIdToken);

      let primaryPlatformToken = sessionStorage.getItem(
        "primary_platform_token"
      );
      let secondaryPlatformToken = sessionStorage.getItem(
        "secondary_platform_token"
      );
      state.merging.primaryPlatformToken = secondaryPlatformToken;
      state.merging.secondaryPlatformToken = primaryPlatformToken;
      sessionStorage.setItem("primary_platform_token", secondaryPlatformToken);
      sessionStorage.setItem("secondary_platform_token", primaryPlatformToken);

      let primaryUserName = sessionStorage.getItem("primary_user_name");
      let secondaryUserName = sessionStorage.getItem("secondary_user_name");
      state.merging.primaryUserName = secondaryUserName;
      state.merging.secondaryUserName = primaryUserName;
      sessionStorage.setItem("primary_user_name", secondaryUserName);
      sessionStorage.setItem("secondary_user_name", primaryUserName);

      let primaryHash = sessionStorage.getItem("primary_hash");
      let secondaryHash = sessionStorage.getItem("secondary_hash");
      state.merging.primaryHash = secondaryHash;
      state.merging.secondaryHash = primaryHash;
      sessionStorage.setItem("primary_hash", secondaryHash);
      sessionStorage.setItem("secondary_hash", primaryHash);

      for (let platform in mergePlatforms) {
        sessionStorage.removeItem(PRIMARY_ACCESS_TOKEN_PREFIX + platform);
        sessionStorage.removeItem(SECONDARY_ACCESS_TOKEN_PREFIX + platform);
      }

      for (let [platform, token] in primaryTokens) {
        if (token) {
          sessionStorage.setItem(
            SECONDARY_ACCESS_TOKEN_PREFIX + platform,
            token
          );
        }
      }

      for (let [platform, token] in secondaryTokens) {
        if (token) {
          sessionStorage.setItem(PRIMARY_ACCESS_TOKEN_PREFIX + platform, token);
        }
      }
    },
  },
  actions: {
    async redeemCode({ state }, data) {
      var res = await fetch(`${backendServiceURL}/api/user/redeem-code`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.user.id_token}`,
        },
        body: JSON.stringify(data),
      });

      return await res.json();
    },

    async queryPrimaryAccountBeforeMerge({ getters, commit }, authRes) {
      var url = `${backendServiceURL}/api/user/query-primary-before-merge?access_token=${authRes.token}`;
      var res = await fetch(url, {
        method: "GET",
        headers: { Authorization: `Bearer ${authRes.id_token}` },
      });

      var query;
      try {
        query = await res.json();
      } catch (error) {
        return {
          errors: [
            {
              title: getters.translate("MergeErrorQueryAccountHeader"),
              detail: getters.translate("BackendErrorGeneric"),
            },
          ],
        };
      }

      if (query.data) {
        commit("savePrimaryKeyringQueryResults", query.data);
      } else if (query.errors) {
        return {
          errors: [
            {
              title: getters.translate("MergeErrorQueryAccountHeader"),
              detail:
                getters.translateDetail(query.errors[0].detail) +
                ` (${query.errors[0].detail})`,
            },
          ],
        };
      }

      return query;
    },

    async querySecondaryAccountBeforeMerge(
      { getters, state, commit },
      authRes
    ) {
      let primaryPuid = state.merging.primaryKeyring.queryResponse.puid;
      var url = `${backendServiceURL}/api/user/query-secondary-before-merge?access_token=${authRes.token}&primary_puid=${primaryPuid}`;
      var res = await fetch(url, {
        method: "GET",
        headers: { Authorization: `Bearer ${authRes.id_token}` },
      });

      var query;
      try {
        query = await res.json();
      } catch (error) {
        return {
          errors: [
            {
              title: getters.translate("MergeErrorQueryAccountHeader"),
              detail: getters.translate("BackendErrorGeneric"),
            },
          ],
        };
      }

      if (query.data) {
        commit("saveSecondaryKeyringQueryResults", query.data);
      } else if (query.errors) {
        return {
          errors: [
            {
              title: getters.translate("MergeErrorQueryAccountHeader"),
              detail:
                getters.translateDetail(query.errors[0].detail) +
                ` (${query.errors[0].detail})`,
            },
          ],
        };
      }

      return query;
    },

    async requestMissingPermissions({ getters, state }, data) {
      var res = await fetch(
        `${backendServiceURL}/api/user/request-missing-permissions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state.user.id_token}`,
          },
          body: JSON.stringify(data),
        }
      );

      var query;
      try {
        query = await res.json();
      } catch (error) {
        return {
          errors: [
            {
              title: getters.translate("MergeRequestPermissions"),
              detail: getters.translate("BackendErrorGeneric"),
            },
          ],
        };
      }

      if (query.errors) {
        return {
          errors: [
            {
              title: getters.translate("MergeRequestPermissions"),
              detail:
                getters.translateDetail(query.errors[0].detail) +
                ` (${query.errors[0].detail})`,
            },
          ],
        };
      }

      return query;
    },

    async twitchPin({ state }, data) {
      var url = `${accountsServiceURL}/twitch-drops-grant?pin=${data.pin}&token=${state.user.twitch_token}`;
      var res = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (res) {
        res = await res.json();
      }
      return res;
    },

    async linkBareAccount({ getters }, linkBody) {
      var linkRes = await fetch(`${backendServiceURL}/api/link-account`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${linkBody.data.attributes.link_to_id_token}`,
        },
        body: JSON.stringify(linkBody),
      });

      try {
        var res = await linkRes.json();
      } catch (error) {
        return {
          errors: [
            {
              title: getters.translate("BackendErrorGenericHeader"),
              detail: getters.translate("BackendErrorGeneric"),
            },
          ],
        };
      }

      if (res.errors) {
        return {
          errors: [
            {
              title: getters.translate("BackendErrorGenericHeader"),
              detail:
                getters.translateDetail(res.errors[0].detail) +
                ` (${res.errors[0].detail})`,
            },
          ],
        };
      }

      return res;
    },

    async unlinkAccount(_, data) {
      var url = `${accountsServiceURL}/eos-unlink?token=${data.token}`;
      var res = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      return res; // has no response body
    },

    async mergeAccount({ getters, state }) {
      const url = `${backendServiceURL}/api/merge-account`;
      const mergeFromPlatform = state.merging.secondaryPlatform;
      const mergeToPlatform = state.merging.primaryPlatform;
      const mergeFromIdToken = state.merging.secondaryIdToken;
      const mergeToIdToken = state.merging.primaryIdToken;
      const mergeFromPlatformToken = state.merging.secondaryPlatformToken;
      const primaryToken =
        state.merging.primaryKeyring.access_tokens[mergeToPlatform];
      const secondaryToken =
        state.merging.secondaryKeyring.access_tokens[mergeFromPlatform];
      const name = state.merging.secondaryUserName;
      const hash = state.merging.secondaryHash;
      var res = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          data: {
            attributes: {
              merge_to_id_token: mergeToIdToken,
              merge_from_id_token: mergeFromIdToken,
              merge_to_access_token: primaryToken,
              merge_from_access_token: secondaryToken,
              merge_from_platform_token: mergeFromPlatformToken,
              merge_from_platform: mergeFromPlatform,
              merge_from_name: name,
              merge_from_hash: hash,
            },
          },
        }),
      });

      try {
        res = await res.json();
      } catch (error) {
        return {
          errors: [
            {
              title: getters.translate("BackendErrorGenericHeader"),
              detail: getters.translate("BackendErrorGeneric"),
            },
          ],
        };
      }

      if (res.errors) {
        if (res.errors[0].detail === 1108) {
          // TODO - a token has expired
        }

        return {
          errors: [
            {
              title: getters.translate("BackendErrorGenericHeader"),
              detail:
                getters.translateDetail(res.errors[0].detail) +
                ` (${res.errors[0].detail})`,
            },
          ],
        };
      }

      return res;
    },

    async eosAuth({ state, getters }, data) {
      try {
        var authRes = await getEosTokens(data);
        var res = await authRes.json();
      } catch (e) {
        return {
          errors: [
            {
              title: getters.translate("BackendErrorGenericHeader"),
              detail: getters.translate("BackendErrorGeneric"),
            },
          ],
        };
      }

      if (res.id_token && res.token) {
        sessionStorage.setItem("token", res.token);
        state.user.token = res.token;

        sessionStorage.setItem("id_token", res.id_token);
        state.user.id_token = res.id_token;

        sessionStorage.setItem("platform", data.store);
        state.user.platform = data.store;
      }

      return res;
    },
  },
});
