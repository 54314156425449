<template>
  <div v-bind:lang="lang">
    <nav-bar></nav-bar>
    <router-view></router-view>
    <br/>
    <accounts-footer></accounts-footer>
  </div>
</template>

<script>
import AccountsFooter from "./components/AccountsFooter.vue";
import NavBar from "./components/NavBar.vue";

export default {
  beforeMount() {
    // TODO: If we end up adding lang tags to more complex routes.. (/twitch-drops/en-US), we will need to improve this
    var langCode = window.location.pathname.replace("/", "");
    var url = window.location.origin;
    var language = this.$store.getters.getLanguageByCode(langCode);
    if (language) {
      this.$store.state.user.language = langCode;
      sessionStorage.setItem("language", langCode);
      location.href = url;
    } else if (!this.$store.state.user.language) {
      this.$store.state.user.language = "en-US";
      sessionStorage.setItem("language", "en-US");
    }
  },
  computed: {
    lang() {
      return this.$store.state.user.language;
    },
  },
  components: { NavBar, AccountsFooter },
};
</script>

<style>
#app {
  font-family: "Brook";
}

body {
  height: 100vh;
  width: 100%;
  background-color: black;
  color: white;

  background-image: url("./assets/Background.png");
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.box .header {
  flex: 0 1 auto;
}

.box .content {
  flex: 1 1 auto;
}

.navbar-nav > li > .dropdown-menu {
  background-color: #101010;
  top: 60px;
  right: -15px;
  border-radius: 0rem;
}

.navbar-nav > li > .dropdown-menu > .dropdown-item {
  color: white;
  vertical-align: middle;
}

div.dropdown-multicol {
  width: 100vw;
}

button.dropdown-item {
  display: inline-block;
  width: 12%;
}

table {
  margin-left: auto;
  margin-right: auto;
  border: 5px;
  border-color: black;
  height: 100%;
}

.accountButton {
  background-color: black;
  border: 4px solid white;
  border-radius: 10px;

  width: 350px;
  height: 75px;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  display: flex;
  align-items: center;

  margin-bottom: 15px;
}

.accountButton:enabled:hover {
  border-color: #5bbc2b;
  cursor: pointer;
}

.content {
  display: table-cell;
  vertical-align: middle;
  width: 100vw;
}

.text {
  font-family: Arial, sans-serif;
  color: white;
  position: relative;
}

.buttonText {
  font-family: "Brook", Arial, sans-serif;
  color: white;
  position: relative;
  left: 5%;
  width: 278px;
  font-size: 2.25em;
  font-weight: normal;
}

.logo {
  /*this is for the logos on the buttons of the homepage*/
  height: 35px;
  position: relative;
  right: -15px;
  top: 0;
  transform: initial;
}

.storeLogo {
  /*this is for the logo on the "signed into {store}" for success and pin entry pages*/
  height: 45px;
  position: relative;
}

input {
  background-color: black;
  border: 4px solid white;
  border-radius: 10px;

  color: white;
  font-family: Arial, sans-serif;
  font-size: large;
  text-indent: 10px;

  position: relative;
  width: 350px;
  height: 70px;
  bottom: 0vh;
}

input:hover {
  border-color: #5bbc2b;
  cursor: pointer;
}

input:focus {
  border-color: #5bbc2b;
  cursor: pointer;
  outline: none !important;
}

input:invalid {
  border-color: #d63924;
  box-shadow: none;
}

/* disables the arrows on the number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* disables arrows on the number input for firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.submitButton {
  background-color: black;

  border: 4px solid white;
  border-radius: 10px;

  color: white;
  font-family: "Brook", Arial, sans-serif;
  font-size: 3em;

  display: inline-flex;
  justify-content: center; /* center text horizontally */
  align-items: center; /* center text vertically */

  position: relative;
  width: 150px;
  height: 70px;
  top: 3vh;
}

.submitButton:enabled:hover {
  border-color: #5bbc2b;
  cursor: pointer;
}

.submitButton:disabled {
  background-color: #444;
  border-color: #888;
  cursor: not-allowed;
  opacity: .5;
}

.backButton {
  background-color: black;

  border: 4px solid white;
  border-radius: 10px;

  color: white;
  font-family: Arial, sans-serif;
  font-size: 2rem;

  position: relative;
  float: left;
  width: 70px;
  height: 70px;
  top: 3vh;
}

.backButton:hover {
  border-color: #5bbc2b;
  cursor: pointer;
}

h1 {
  font-family: "Brook";
  font-size: 4em;
  font-weight: normal;
  margin-top: 0.5em;
  margin-bottom: 0.67em;
  line-height: normal;
}

h2 {
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  font-weight: bold;
  line-height: normal;
}

.unlink-tooltip .tooltip-text {
  visibility: hidden;
  width: 500px;
  background-color: black;
  color: #fff;
  text-align: left;
  padding: 8px;
  border-radius: 10px;
  position: absolute;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  left: 110%;
  top: 0;
}

.unlink-tooltip .tooltip-text p {
  margin-bottom: 0;
  font-size: 0.4em;
}

.unlink-tooltip .tooltip-text .warning-text {
  color: #d63924;
  font-weight: bold;
}

.unlink-tooltip .tooltip-text .tooltip-header {
  font-size: 0.5em;
}

.unlink-tooltip:hover .tooltip-text {
  visibility: visible;
}

/* If the screen size is 500px wide or less*/
@media screen and (max-width: 500px) {
  body {
    background-image: url("./assets/emailBG.jpg");
  }

  .navbar-nav > li > .dropdown-menu {
    top: 54px;
    right: -17px;
    border-radius: 0rem;
  }

  div.dropdown-multicol {
    width: 100vw;
  }

  button.dropdown-item {
    width: 48%;
  }

  .accountButton {
    height: 45px;
  }

  .logo {
    height: 25px;
  }

  .text {
    margin-top: 1vh;
    margin-bottom: 1vh;
  }

  img {
    height: 48px;
  }
}

.puid {
  font-family: brook;
}

/* Translation overflow styles */

/* Main Page */
.merge-home :lang(ru-RU).buttonText,
.merge-home :lang(tr).buttonText,
.merge-home :lang(de-DE).buttonText {
  font-size: 1.7em;
}

/* Main Sign In */
.merge-sign-in :lang(th).signInButton {
  font-size: 1.5em;
}

/* Unlink Buttons */
:lang(ko-KR).unlinkButton,
:lang(ja-JP).unlinkButton,
:lang(ru-RU).unlinkButton,
:lang(zh-ZW).unlinkButton,
:lang(zh-CN).unlinkButton,
:lang(zh-TW).unlinkButton,
:lang(tr).unlinkButton,
:lang(ar).unlinkButton {
  font-size: 1.6em;
}

:lang(th).unlinkButton {
  font-size: 1.5em;
}

/* Link Buttons */
:lang(th).linkButton {
  font-size: 1.5em;
}

/* Signin Buttons */
:lang(ja-JP).signInButton,
:lang(ar).signInButton {
  font-size: 1.5em;
}

/* Finish Merge */
.finish-merge :lang(de-DE).submitButton,
.finish-merge :lang(nl-NL).submitButton {
  font-size: 2em;
}

.finish-merge :lang(ru-RU).submitButton {
  font-size: 1.4em;
}

/* Unlink Attention */
.unlink-attention :lang(ja-JP).signInButton,
.unlink-attention :lang(th).signInButton {
  font-size: 1.6em;
}

.unlink-attention :lang(th).submitButton, 
.unlink-attention :lang(ru-RU).submitButton {
  font-size: 3em;
}

/* Merge Success */
.merge-success :lang(pl).submitButton,
.merge-success :lang(ko-KR).submitButton,
.merge-success :lang(ar).submitButton {
  font-size: 2.2em;
}

.merge-success :lang(th).submitButton,
.merge-success :lang(fr-FR).submitButton,
.merge-success :lang(tr).submitButton,
.merge-success :lang(id).submitButton,
.merge-success :lang(zh-TW).submitButton,
.merge-success :lang(ms).submitButton,
.merge-success :lang(pt-PT).submitButton,
.merge-success :lang(pt-BR).submitButton,
.merge-success :lang(zh-CN).submitButton,
.merge-success :lang(es-MX).submitButton,
.merge-success :lang(it-IT).submitButton,
.merge-success :lang(de-DE).submitButton,
.merge-success :lang(nl-NL).submitButton,
.merge-success :lang(ru-RU).submitButton {
  font-size: 3.2em;
}

.merge-success :lang(ja-JP).submitButton {
  font-size: 2.8em;
}

/* Friends Warning */
.friends-attention :lang(ms).submitButton,
.friends-attention :lang(ar).submitButton,
.friends-attention :lang(tr).submitButton,
.friends-attention :lang(th).submitButton,
.friends-attention :lang(id).submitButton,
.friends-attention :lang(zh-TW).submitButton,
.friends-attention :lang(zh-CN).submitButton,
.friends-attention :lang(fil).submitButton,
.friends-attention :lang(pt-PT).submitButton,
.friends-attention :lang(pt-BR).submitButton,
.friends-attention :lang(fr-FR).submitButton,
.friends-attention :lang(it-IT).submitButton,
.friends-attention :lang(nl-NL).submitButton {
  font-size: 3em;
}

.friends-attention :lang(ko-KR).submitButton,
.friends-attention :lang(ja-JP).submitButton,
.friends-attention :lang(ru-RU).submitButton,
.friends-attention :lang(es-MX).submitButton,
.friends-attention :lang(es-ES).submitButton,
.friends-attention :lang(de-DE).submitButton {
  font-size: 2.6em;
}

/* Merge Error */
.merge-error :lang(ko-KR).submitButton,
.merge-error :lang(ru-RU).submitButton {
  font-size: 3em;
}

</style>
