<template>
  <div class="row d-flex justify-content-center text-center">
    <div class="col-md-5 m-4 p-4">
      <h1 class="mb-4 header">{{ RedeemHeader }}</h1>
      <h5 class="mb-3">{{ RedeemDetail }}</h5>

      <div class="mb-5">
        <img
          class="storeLogo"
          :src="require(`../assets/Placeholder_${store}.png`)"
          :alt="`${store} Logo`"
        />
      </div>

      <div class="text text-success mb-5" v-if="page.success">
        {{ RedeemGenericSuccessMessage }}
      </div>

      <div class="text text-danger mb-5" v-if="page.error">
        {{ RedeemErrorMessage(page.error.detail) }}
      </div>

      <form v-on:submit="redeemCode">
        <input
          v-model="data.code"
          class="text-center mb-3"
          type="text"
          maxlength="14"
          placeholder="- - - - - - - - - - - - - -"
        />
        <br />
        <button type="submit" class="submitButton">
          {{ RedeemButtonText }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: {
        waiting: false,
        success: false,
        error: null,
        item: null,
      },
      data: {
        code: "",
      },
    };
  },
  computed: {
    store() {
      return this.$store.state.user.platform;
    },
    RedeemHeader() {
      return this.$store.getters.translate("RedeemHeader");
    },
    RedeemDetail() {
      let storeStr = this.$store.state.user.platform;
      let store = storeStr.charAt(0).toUpperCase() + storeStr.slice(1);
      return this.$store.getters.translate("RedeemDetail") + " " + store;
    },
    RedeemButtonText() {
      return this.$store.getters.translate("RedeemButtonText");
    },
    RedeemGenericSuccessMessage() {
      return this.$store.getters.translate("RedeemGenericSuccessMessage");
    },
    RedeemGenericErrorMessage() {
      return this.$store.getters.translate("RedeemGenericErrorMessage");
    },
  },
  methods: {
    RedeemErrorMessage(detail) {
      return this.$store.getters.translateDetail(detail);
    },
    async redeemCode(e) {
      e.preventDefault();

      this.page.error = null;
      this.page.errorText = null;
      this.page.success = false;
      this.page.waiting = true;

      if (this.data.code == "") {
        return;
      }

      this.$store
        .dispatch("redeemCode", {
          data: {
            type: "code",
            attributes: {
              code: this.data.code,
            },
          },
        })
        .then((res) => {
          this.page.waiting = false;

          // TODO - error handling
          if (!res) {
            this.page.error = { detail: -1 };
          } else if (res.errors) {
            this.page.error = res.errors[0];
          } else {
            let itempath = res.data.attributes.item_id.split("/");
            this.page.item = itempath[itempath.length - 1];
            this.page.success = true;
          }
        })
        .catch((err) => {
          console.log(err);
          this.page.error = { detail: -2 };
          this.page.waiting = false;
        });
    },
  },
  beforeMount() {
    if (!this.$store.getters.isAuthed) {
      this.$router.push({ name: "redeemAuth" });
    }
  },
};
</script>

<style scoped>
/* Header Text */
:lang(ja-JP).header,
:lang(ru).header {
  font-size: 3.5rem;
}

/* Submit Button */
:lang(th).submitButton,
:lang(ru).submitButton,
:lang(ja-JP).submitButton {
  font-size: 1.25em;
}
:lang(ar).submitButton {
  font-size: 2em;
}
</style>
