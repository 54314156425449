<template>
  <div class="outer-container unlink-attention">
    <div class="header-info">
      <h1>{{ AttentionText }}</h1>
      <p class="attention-header" v-bind:class="!MergeDisabled && 'disabled'">
        {{ AttentionHeader }}
      </p>
      <p class="attention-message" v-bind:class="!MergeDisabled && 'disabled'">
        {{ AttentionMessage }}
      </p>
    </div>

    <div>
      <h3 class="primary-header">{{ NewAccountText }}</h3>
      <div class="platform-box">
        <table>
          <tr><p class="puid"> {{SecondaryPuid}} </p></tr>
          <tr>
            <td>
              <button class="accountButton primary">
                <img
                  class="logo"
                  :src="platforms[platformToLink].logoSrc"
                  alt="Logo"
                />

                <h3 class="buttonText">
                  {{ platforms[platformToLink].name }}
                </h3>
              </button>
            </td>
            <td>
              <button class="unlinkButton buttonText" :disabled="true">
                {{ PrimaryText }}
              </button>
            </td>
          </tr>
        </table>
        <hr v-if="MergeDisabled" class="half-width mt-2 mb-4" />
        <div v-for="(platform, key) in platforms" :key="key">
          <table v-if="ShowLinkedPlatform(key)">
            <tr>
              <td>
                <button class="accountButton">
                  <img class="logo" v-bind:src="platform.logoSrc" alt="Logo" />

                  <h1 class="buttonText">
                    {{ platform.name }}
                  </h1>
                </button>
              </td>
              <td>
                <button
                  class="unlinkButton buttonText unlink-tooltip"
                  v-if="!ShowSignInButton(key)"
                  v-on:click="Unlink(key)"
                >
                  {{ UnlinkText }}
                  <div class="tooltip-text">
                    <p class="tooltip-header warning-text">
                      {{ MergingAttention }}
                    </p>
                    <p class="warning-text">
                      {{ MergingAttentionTooltipWarning }}
                    </p>
                    <p>{{ MergingAttentionTooltipDetails }}</p>
                  </div>
                </button>
                <button
                  class="signInButton buttonText"
                  v-if="ShowSignInButton(key)"
                  v-on:click="SignIn(platform)"
                >
                  {{ SignInText }}
                </button>
              </td>
            </tr>
          </table>
        </div>
        <img
          v-if="MergeDisabled"
          class="mergeable-icon"
          src="../assets/MergeCross.png"
        />
        <img
          v-if="!MergeDisabled"
          class="mergeable-icon"
          src="../assets/MergeCheckmark.png"
        />
      </div>
    </div>
    <div class="button-box">
      <button class="backButton" v-if="MergeDisabled" v-on:click="back">
        {{ BackIcon }}
      </button>
      <button
        class="submitButton"
        v-on:click="Continue"
        :disabled="MergeDisabled"
      >
        {{ ReturnButtonText }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      platforms: this.$store.getters.platformData,
      secondaryPlatforms:
        this.$store.state.merging.secondaryKeyring.queryResponse.platforms,
      secondaryTokens: this.$store.state.merging.secondaryKeyring.access_tokens,
      platformToLink: this.$store.state.merging.secondaryPlatform,
    };
  },
  async mounted() {
    // now that we've redirected successfully, we can delete the cookie
    document.cookie =
      "REDIRECT=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;samesite=none;secure";

    if (!this.$route.query.token || !this.$route.query.store) {
      return;
    }
    const eosAuthBody = {
      store: this.$route.query.store,
      token: this.$route.query.token,
      name: this.$route.query.name,
      hash: this.$route.query.userhash,
    };
    let store = eosAuthBody.store;
    // TODO: HANDLE THIS SOMEWHERE ELSE? ALSO NEEDED IN MERGE SIGNIN
    if (store === "epic") {
      // The stores used by the backend and the stores used by the /me keychain route
      // are all the same - except for the Epic store and XBL
      store = "epicgames";
    } else if (store === "microsoft") {
      store = "xbl";
    }
    const authRes = await this.$store.dispatch("eosAuth", eosAuthBody);
    if (authRes.status && (authRes.status >= 300 || authRes.status < 200)) {
      this.$store.commit("saveMergingError", {
        error: "Error Unlinking Accounts",
        errorDetail: authRes.errors[0].error_text,
      });
      this.$router.push({ name: "mergeError" });
      return;
    }
    this.$store.commit("addSecondaryMergeAccessToken", {
      platform: store,
      token: authRes.token,
    });
  },
  computed: {
    SecondaryPuid() {
      return "PUID: " + this.$store.state.merging.secondaryKeyring?.queryResponse?.puid;
    },
    AttentionText() {
      return this.$store.getters.translate("MergingAttention");
    },
    AttentionHeader() {
      return this.$store.getters.translate("MergingAttentionHeader");
    },
    AttentionMessage() {
      return this.$store.getters.translate("MergingAttentionMessage");
    },
    NewAccountText() {
      return this.$store.getters.translate("MergingToBeMerged");
    },
    SignInText() {
      return this.$store.getters.translate("MergingSignIn");
    },
    UnlinkText() {
      return this.$store.getters.translate("MergingUnlink");
    },
    PrimaryText() {
      return this.$store.getters.translate("MergingPrimary");
    },
    ReturnButtonText() {
      return this.$store.getters.translate("MergingContinue");
    },
    MergingAttention() {
      return this.$store.getters.translate("MergingAttention");
    },
    MergingAttentionTooltipWarning() {
      return this.$store.getters.translate("MergingAttentionTooltipWarning");
    },
    MergingAttentionTooltipDetails() {
      return this.$store.getters.translate("MergingAttentionTooltipDetails");
    },
    BackIcon() {
      return "◀";
    },
    MergeDisabled() {
      return this.secondaryPlatforms.length > 1;
    },
  },
  methods: {
    async Continue(e) {
      e.preventDefault();
      this.$router.push({ name: "finishMergeAccounts" });
    },
    SignIn(platform) {
      if (platform.cookie) {
        document.cookie = platform.cookie;
      }
      document.cookie = `REDIRECT=mergeAttentionUnlink;max-age=600;path=/;samesite=none;secure`; // expires after 10 min
      window.location.href = platform.url;
    },
    async Unlink(platform) {
      const token =
        this.$store.state.merging.secondaryKeyring.access_tokens[platform];
      const res = await this.$store.dispatch("unlinkAccount", {
        token: token,
      });
      if (!res) {
        this.page.error = {
          detail: "An error occurred. Please try again later",
        };
      } else if (res.errors) {
        this.$store.commit("saveMergingError", {
          error: "Error Unlinking Accounts",
          errorDetail: res.errors[0].error_text,
        });
        this.$router.push({ name: "mergeError" });
      } else {
        this.$store.commit("unlinkSecondaryPlatform", platform);
        this.secondaryPlatforms =
          this.$store.state.merging.secondaryKeyring.queryResponse.platforms;
      }
    },
    async back(e) {
      e.preventDefault();

      this.$router.push({ name: "finishMergeAccounts" });
    },
    ShowLinkedPlatform(platform) {
      return (
        this.secondaryPlatforms.includes(platform) &&
        platform !== this.platformToLink
      );
    },
    ShowSignInButton(platform) {
      return !this.secondaryTokens[platform];
    },
  },
};
</script>
<style scoped>
template {
  height: 100%;
}

.outer-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.platform-box {
  position: relative;
  background-color: #292929;
  border: 4px solid #121212;
  border-radius: 10px;

  width: auto;
  min-height: 250px;
  padding: 2em;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mergeable-icon {
  height: 4em;
  width: 4em;
  position: absolute;
  bottom: -2em;
  left: 45%;
}

.accountButton {
  max-width: 20vw;
  height: 65px;
  transform: none;
  left: 0;
  pointer-events: none;
}

.primary-header {
  order: -3;
  font-family: "Brook", Ariel;
  font-size: 2.5em;
}

.button-box {
  display: flex;
  left: 34%;
  margin-top: 3%;
}

.backButton {
  height: 115px;
  width: 115px;
  font-size: 5em;
  margin-bottom: 0;
  margin-right: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.submitButton {
  height: 115px;
  width: 325px;
  font-size: 5em;
}

.unlinkButton {
  background-color: #d63924;
  border: 4px solid #530d03;
  border-radius: 10px;

  width: 150px;
  height: 70px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 10px;
  margin-bottom: 15px;
}

.signInButton {
  background-color: #5bbc2b;
  border: 4px solid #183e09;
  border-radius: 10px;

  width: 150px;
  height: 70px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 10px;
  margin-bottom: 15px;
}

.unlinkButton:hover:enabled,
.signInButton:hover:enabled {
  border-color: white;
  cursor: pointer;
}

.unlinkButton:disabled {
  background-color: #444;
  border: 4px solid #888;
  opacity: .5;
}

.header-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50%;
}

.attention-header {
  color: #d63924;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
}

.attention-message {
  text-align: center;
}

.attention-header.disabled,
.attention-message.disabled {
  opacity: .1;
}

.puid {
  font-size: 1.5em;
  margin-bottom: 0.2em;
}

/* medium screens */
@media screen and (max-width: 1200px) {
  .platform-box {
    width: 40vw;
  }

  .accountButton {
    width: 30vw;
  }
}

/* small screens */
@media screen and (max-width: 500px) {
  body {
    background: #020916;
    background-image: none;
  }

  .primary-header {
    font-size: 2.5em;
  }

  .platform-box {
    width: 66vw;
    min-height: 100px;
  }

  .accountButton {
    width: 50vw;
    height: 50px;
    font-size: 0.75em;
  }
}
</style>
