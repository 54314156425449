<template>
  <div class="box">
    <div class="header" id="header"></div>

    <div class="content">
      <table id="table">
        <tr>
          <td>
            <h1 class="text" id="header" align="center">
              {{ LinkingPinHeader }}
            </h1>
            <img
              class="storeLogo"
              :src="require(`../assets/Placeholder_${store}.png`)"
              :alt="`${store} Logo`"
            />
          </td>
        </tr>
        <tr>
          <td>
            <p class="text" id="detail" align="center">
              {{ LinkingPinDetail }}
            </p>
            <input
              id="textinput"
              type="number"
              placeholder="— — — — — —"
              v-model="pin"
            />
            <p class="text" id="submitErrorOutput"></p>
          </td>
        </tr>
        <tr>
          <td>
            <button class="submitButton" id="submit" v-on:click="submitClick()">
              <h2>
                {{ LinkingPinLinkButton }}
              </h2>
            </button>
            <div v-if="page.error">
              <div class="invalid-pin">{{ page.error.detail }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <button class="submitButton" id="tryAgain" onclick="onLoad()">
              <h2>
                {{ LinkingPinTryAgainButton }}
              </h2>
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: {
        error: null,
      },
      pin: null,
    };
  },
  methods: {
    async submitClick() {
      const twitchToken = this.$store.state.user.twitch_token;
      let res;
      if (twitchToken) {
        res = await this.$store.dispatch("twitchPin", {
          pin: this.pin,
        });
      }
      if (!res) {
        this.page.error = {
          detail: "An error occurred. Please try again later",
        };
      } else if (res.errors) {
        this.page.error = res.errors[0];
      } else {
        this.$router.push({ name: "success" });
      }
    },
  },
  computed: {
    store() {
      return this.$store.state.user.platform;
    },
    LinkingPinHeader() {
      return this.$store.getters.translateWithPlatform("LinkingPinHeader");
    },
    LinkingPinDetail() {
      return this.$store.getters.translate("LinkingPinDetail");
    },
    LinkingPinLinkButton() {
      return this.$store.getters.translate("LinkingPinLinkButton");
    },
    LinkingPinTryAgainButton() {
      return this.$store.getters.translate("LinkingPinTryAgainButton");
    },
  },
  beforeMount() {},
};
</script>

<style scoped>
input {
  background-image: url("../assets/CodeInputField.png");
  background-size: 100% 100%;
  background-color: transparent;
  border: none;
  position: relative;
  width: 300px;
  height: 50px;
  bottom: 0vh;
  text-align: center;
  font-family: Arial, sans-serif;
  color: #ffffff;
  font-size: x-large;
}

input:hover {
  background-image: url("../assets/CodeInputFieldHover.png");
}

input:focus {
  background-image: url("../assets/CodeInputFieldHover.png");
  outline: none !important;
}

input:invalid {
  background-image: url("../assets/CodeInputFieldInvalid.png");
  box-shadow: none;
}

td {
  text-align: center;
}

.invalid-pin {
  position: absolute;
  top: 75vh;
  left: 50%;
  transform: translateX(-50%);
  width: 20vw;
  text-align: center;
  color: red;
  font-size: medium;
}

.submitButton h2 {
  position: relative;
  bottom: 10px;
}

/* disables the arrows on the number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* disables arrows on the number input for firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
