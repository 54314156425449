<template>
  <div class="merge-sign-in row d-flex justify-content-center text-center m-5 p-1">
    <h1 class="mb-5">{{ MergingSignInHeader }}</h1>

    <div class="mergeSignInContainer">
      <p class="puid">{{ Puid }}</p>
      <div v-for="platform in page.signedIn" :key="platform.platformKey">
        <table>
          <tr>
            <td>
              <button class="accountButton">
                <img class="logo" v-bind:src="platform.logoSrc" alt="Logo" />

                <h1 class="buttonText">
                  {{ platform.name }}
                </h1>
              </button>
            </td>
            <td>
              <button
                class="unlinkButton buttonText unlink-tooltip"
                v-on:click="Unlink(platform.platformKey)"
                :disabled="DisableUnlinkButton"
              >
                {{ UnlinkText }}
                <div class="tooltip-text">
                  <p class="tooltip-header warning-text">
                    {{ MergingAttention }}
                  </p>
                  <p class="warning-text">
                    {{ MergingSignInTooltipWarning }}
                  </p>
                  <p>{{ MergingSignInTooltipDetails }}</p>
                </div>
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div v-for="platform in page.linked" :key="platform.platformKey">
        <table>
          <tr>
            <td>
              <button class="accountButton">
                <img class="logo" :src="platform.logoSrc" alt="Logo" />

                <h1 class="buttonText">
                  {{ platform.name }}
                </h1>
              </button>
            </td>
            <td>
              <button
                class="signInButton buttonText"
                v-on:click="SignInOrLink(platform)"
              >
                {{ SignInText }}
              </button>
            </td>
          </tr>
        </table>
      </div>
      <hr class="half-width mt-2 mb-4" />
      <h3 class="mb-4">{{ OtherPlatforms }}</h3>
      <div v-for="platform in page.others" :key="platform.platformKey">
        <table>
          <tr>
            <td>
              <button class="accountButton">
                <img class="logo" :src="platform.logoSrc" alt="Logo" />

                <h1 class="buttonText">
                  {{ platform.name }}
                </h1>
              </button>
            </td>
            <td>
              <button
                class="linkButton buttonText"
                v-on:click="SignInOrLink(platform)"
              >
                {{ LinkText }}
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      platforms: this.$store.getters.platformData,

      page: {
        signedIn: [],
        linked: [],
        others: [],
      },
    };
  },
  computed: {
    DisableUnlinkButton() {
      return this.page.signedIn.length == 1;
    },
    Puid() {
      return "PUID: " + this.$store.state.merging.primaryKeyring?.queryResponse?.puid;
    },
    MergingSignInHeader() {
      return this.$store.getters.translate("MergingAccountManagement");
    },
    LinkText() {
      return this.$store.getters.translate("MergingLink");
    },
    UnlinkText() {
      return this.$store.getters.translate("MergingUnlink");
    },
    SignInText() {
      return this.$store.getters.translate("MergingSignIn");
    },
    OtherPlatforms() {
      return this.$store.getters.translate("MergingSignInOtherPlatforms");
    },
    MergingAttention() {
      return this.$store.getters.translate("MergingAttention");
    },
    MergingSignInTooltipWarning() {
      return this.$store.getters.translate("MergingSignInTooltipWarning");
    },
    MergingSignInTooltipDetails() {
      return this.$store.getters.translate("MergingSignInTooltipDetails");
    },
  },
  methods: {
    SignInOrLink(platform) {
      if (platform.cookie) {
        document.cookie = platform.cookie;
      }
      document.cookie = `REDIRECT=merge;max-age=600;path=/;samesite=none;secure`; // expires after 10 min
      window.location.href = platform.url;
    },
    async Unlink(platform) {
      const token =
        this.$store.state.merging.primaryKeyring.access_tokens[platform];
      const res = await this.$store.dispatch("unlinkAccount", {
        token: token,
      });
      if (!res) {
        this.page.error = {
          detail: "An error occurred. Please try again later",
        };
      } else if (res.errors) {
        this.$store.commit("saveMergingError", {
          error: "Error Unlinking Accounts",
          errorDetail: res.errors[0].error_text,
        });
        this.$router.push({ name: "mergeError" });
      } else {
        this.$store.commit("unlinkPrimaryPlatform", platform);
        this.PopulatePlatformLists();
        return;
      }
    },

    // Helpers for the mounted hook
    async FirstVisit(eosAuthBody, store) {
      let authRes = await this.$store.dispatch("eosAuth", eosAuthBody);
      if (authRes.errors) {
        this.$store.commit("saveMergingError", {
          error: authRes.errors[0].title,
          errorDetail: authRes.errors[0].detail,
        });
        this.$router.push({ name: "mergeError" });
        return;
      }
      this.$store.commit("addPrimaryMergeAccessToken", {
        platform: store,
        token: authRes.token,
      });
      this.$store.commit("savePrimaryMergeDetails", {
        platform: store,
        id_token: authRes.id_token,
        platform_token: eosAuthBody.token,
        name: eosAuthBody.name,
        hash: eosAuthBody.hash,
      });
      let queryRes = await this.$store.dispatch(
        "queryPrimaryAccountBeforeMerge",
        authRes
      );

      if (queryRes.errors) {
        this.$store.commit("saveMergingError", {
          error: queryRes.errors[0].title,
          errorDetail: queryRes.errors[0].detail,
        });
        this.$router.push({ name: "mergeError" });
        return;
      }
    },
    async LinkDanglingAccount(eosAuthBody, primaryPlatform, secondaryPlatform) {
      const primaryAccessToken =
        this.$store.state.merging.primaryKeyring.access_tokens[primaryPlatform];
      const primaryIdToken = this.$store.state.merging.primaryIdToken;
      const res = await this.$store.dispatch("linkBareAccount", {
        data: {
          type: "link_bare_account",
          attributes: {
            link_to_access_token: primaryAccessToken,
            link_to_id_token: primaryIdToken,
            link_from_platform_token: eosAuthBody.token,
            link_from_platform: secondaryPlatform,
            link_from_name: eosAuthBody.name,
            link_from_hash: eosAuthBody.hash,
          },
        },
      });
      if (res?.errors) {
        this.$store.commit("saveMergingError", {
          error: res.errors[0].title,
          errorDetail: res.errors[0].detail,
        });
        this.$router.push({ name: "mergeError" });
        return;
      }

      let queryRes = await this.$store.dispatch(
        "queryPrimaryAccountBeforeMerge",
        {
          token: primaryAccessToken,
          id_token: primaryIdToken,
        }
      );
      if (queryRes.errors) {
        this.$store.commit("saveMergingError", {
          error: queryRes.errors[0].title,
          errorDetail: queryRes.errors[0].detail,
        });
        this.$router.push({ name: "mergeError" });
        return;
      }
    },
    async SignedIntoAnotherKeyring(eosAuthBody, authRes, store) {
      this.$store.commit("addSecondaryMergeAccessToken", {
        platform: store,
        token: authRes.token,
      });
      this.$store.commit("saveSecondaryMergeDetails", {
        platform: store,
        id_token: authRes.id_token,
        platform_token: eosAuthBody.token,
        name: eosAuthBody.name,
        hash: eosAuthBody.hash,
      });

      let queryRes = await this.$store.dispatch(
        "querySecondaryAccountBeforeMerge",
        authRes
      );

      if (queryRes.errors) {
        this.$store.commit("saveMergingError", {
          error: queryRes.errors[0].title,
          errorDetail: queryRes.errors[0].detail,
        });
        this.$router.push({ name: "mergeError" });
        return;
      }

      if (queryRes.data.swap_for_kws) {
        console.log("swapping for KWS");
        this.$store.commit("swapPrimaryAndSecondary");
      }

      this.$router.push({ name: "finishMergeAccounts" });
    },
    PopulatePlatformLists() {
      let primaryPlatforms =
        this.$store.state.merging.primaryKeyring.queryResponse?.platforms;
      let primaryTokens =
        this.$store.state.merging.primaryKeyring.access_tokens;
      if (!primaryPlatforms || !primaryTokens) {
        return;
      }
      this.page.signedIn = [];
      this.page.linked = [];
      this.page.others = [];

      // populate signed in and linked lists
      primaryPlatforms.forEach((platform) => {
        if (primaryTokens[platform]) {
          this.page.signedIn.push(this.platforms[platform]);
        } else if (this.platforms[platform]) {
          this.page.linked.push(this.platforms[platform]);
        }
      });

      // populate other platforms list
      for (let platform in this.platforms) {
        if (!primaryPlatforms.includes(platform)) {
          this.page.others.push(this.platforms[platform]);
        }
      }
    },
    async authNewDanglingAccount(eosAuthBody) {
      let authRes = await this.$store.dispatch("eosAuth", eosAuthBody);
      if (authRes?.errors) {
        this.$store.commit("saveMergingError", {
          error: authRes.errors[0].title,
          errorDetail: authRes.errors[0].detail,
        });
        this.$router.push({ name: "mergeError" });
        return;
      }
      this.$store.commit("addPrimaryMergeAccessToken", {
        platform: eosAuthBody.store,
        token: authRes.token,
      });
      this.PopulatePlatformLists();
    },
  },

  async mounted() {
    // now that we've redirected successfully, we can delete the cookie
    document.cookie =
      "REDIRECT=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;samesite=none;secure";
    let eosAuthBody = {
      store: this.$route.query.store,
      token: this.$route.query.token,
      name: this.$route.query.name,
      hash: this.$route.query.userhash,
    };

    let primaryKeyringQueryResponse =
      this.$store.state.merging.primaryKeyring.queryResponse;

    let store = eosAuthBody.store;
    if (!store) {
      if (primaryKeyringQueryResponse) {
        this.PopulatePlatformLists();
      } else {
        this.$router.push({ name: "mergeAccounts" });
      }
      return;
    } else if (store === "epic") {
      // The stores used by the backend and the stores used by the /me keychain route
      // are all the same - except for the Epic store and XBL
      store = "epicgames";
    } else if (store === "microsoft") {
      store = "xbl";
    }

    if (!primaryKeyringQueryResponse) {
      console.log("first time reaching merge signin page. platform", store);
      await this.FirstVisit(eosAuthBody, store);
      this.PopulatePlatformLists();
      return;
    }

    if (this.$store.getters.primaryKeyringAuthed(store)) {
      console.log("already signed in on platform " + store);
      this.PopulatePlatformLists();
      return;
    }
    // get EOS tokens
    let authRes = await this.$store.dispatch("eosAuth", eosAuthBody);
    const primaryPlatform = this.$store.state.merging.primaryPlatform;
    if (authRes.errors) {
      if (authRes.errors[0]?.code === 404 && primaryPlatform) {
        await this.LinkDanglingAccount(eosAuthBody, primaryPlatform, store).then(
          () => {
            this.authNewDanglingAccount(eosAuthBody);
          }
        );
        this.PopulatePlatformLists();
      } else {
        this.$store.commit("saveMergingError", {
          error: authRes.errors[0].title,
          errorDetail: authRes.errors[0].detail,
        });
        this.$router.push({ name: "mergeError" });
      }
      return;
    }

    if (
      primaryKeyringQueryResponse &&
      !primaryKeyringQueryResponse.platforms.includes(store)
    ) {
      console.log("new keyring. platform", store);
      await this.SignedIntoAnotherKeyring(eosAuthBody, authRes, store);
      return;
    }

    // save the new token to the primary keyring
    this.$store.commit("addPrimaryMergeAccessToken", {
      platform: store,
      token: authRes.token,
    });

    console.log("new platform on primary keyring. platform", store);
    this.PopulatePlatformLists();
  },
};
</script>

<style scoped>
hr.half-width {
  width: 520px;
  margin: 0 auto;
  border: 1px solid white;
  opacity: 1;
}
h3 {
  font-family: "Brook", Ariel;
  font-size: 3em;
}

.accountButton {
  height: 70px;
}

.accountButton:hover {
  border-color: white;
  cursor: default;
}

.mergeSignInContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: auto;
}

.unlinkButton {
  background-color: #d63924;
  border: 4px solid #530d03;
  border-radius: 10px;

  width: 150px;
  height: 70px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 10px;
  margin-bottom: 15px;
}

.unlink-tooltip:hover .tooltip-text {
  visibility: visible;
}

.unlinkButton:disabled {
  background-color: #444;
  border: 4px solid #888;
  opacity: .5;
}

.unlinkButton:disabled .tooltip-text {
  visibility: hidden;
}

.unlinkButton:hover:enabled {
  border-color: white;
  cursor: pointer;
}

.signInButton {
  background-color: black;
  border: 4px solid white;
  border-radius: 10px;

  width: 150px;
  height: 70px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 10px;
  margin-bottom: 15px;
}

.signInButton:hover {
  border-color: #5bbc2b;
  cursor: pointer;
}

.linkButton {
  background-color: #5bbc2b;
  border: 4px solid #183e09;
  border-radius: 10px;

  width: 150px;
  height: 70px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 10px;
  margin-bottom: 15px;
}

.linkButton:hover {
  border-color: white;
  cursor: pointer;
}

.puid {
  font-size: 1.8em;
  margin-left: 1.3%;
  align-self: flex-start;
  margin-bottom: 1%;
}
</style>
