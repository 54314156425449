<template>
  <div class="outer-container friends-attention">
    <div class="header-info">
      <h1>{{ AttentionText }}</h1>
      <p class="friends-header">
        {{ AttentionHeader }}
      </p>
      <p class="friends-message">
        {{ AttentionMessage }}
      </p>
    </div>

    <div>
      <h3 class="primary-header">{{MergeResultText}}</h3>
      <div class="resulting-platforms">
        <p class="puid">{{ Puid }}</p>
        <div v-for="(platform, key) in platforms" :key="key">
          <button
            class="accountButton"
            v-if="ShowPrimaryPlatform(key) || ShowSecondaryPlatform(key)"
            disabled
          >
            <img class="logo" :src="platform.logoSrc" alt="Logo" />

            <h3 class="buttonText">
              {{ platform.name }}
            </h3>
          </button>
        </div>
      </div>
    </div>
    <div class="button-box">
      <button class="backButton" v-on:click="back">
        {{ BackIcon }}
      </button>
      <button
        class="submitButton"
        v-on:click="MergeAnyway"
      >
        {{ MergeAnywayText }}
      </button>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      platforms: this.$store.getters.platformData,
    };
  },
  async mounted() {
    // now that we've redirected successfully, we can delete the cookie
    document.cookie =
      "REDIRECT=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;samesite=none;secure";
  },
  computed: {
    Puid() {
      return "PUID: " + this.$store.state.merging.primaryKeyring?.queryResponse?.puid;
    },
    AttentionText() {
      return this.$store.getters.translate("MergingAttention");
    },
    AttentionHeader() {
      return this.$store.getters.translate("MergeAttentionFriendsHeader");
    },
    AttentionMessage() {
      return this.$store.getters.translate("MergeAttentionFriendsMessage");
    },
    MergeResultText() {
      return this.$store.getters.translate("MergingResult");
    },
    MergeAnywayText() {
      return this.$store.getters.translate("MergeAttentionFriendsMergeButton");
    },
    BackIcon() {
      return "◀";
    },
    PrimaryPlatforms() {
      return this.$store.state.merging.primaryKeyring.queryResponse.platforms;
    },
    SecondaryPlatforms() {
      return this.$store.state.merging.secondaryKeyring.queryResponse.platforms;
    },
  },
  methods: {
    async MergeAnyway(e) {
      e.preventDefault();

      // Since this is the last step (unlinking secondary accounts and kws permissions are checked first),
      // merge anyway can directly call merge
      let res = await this.$store.dispatch("mergeAccount", null);
      if (res?.errors) {
        this.$store.commit("saveMergingError", {
          error: "Error Merging Accounts",
          errorDetail: res.errors[0].error_text,
        });
        this.$router.push({ name: "mergeError" });
        return;
      }
      this.$router.push({ name: "mergeSuccess" });
    },
    async back(e) {
      e.preventDefault();

      this.$router.push({ name: "finishMergeAccounts" });
    },
    ShowPrimaryPlatform(platform) {
      return this.PrimaryPlatforms.includes(platform);
    },
    ShowSecondaryPlatform(platform) {
      return this.SecondaryPlatforms.includes(platform);
    },
  },
};
</script>
<style scoped>
template {
  height: 100%;
}

.outer-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.platform-box {
  position: relative;
  background-color: #292929;
  border: 4px solid #121212;
  border-radius: 10px;

  width: auto;
  min-height: 250px;
  padding: 2em;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mergeable-icon {
  height: 4em;
  width: 4em;
  position: absolute;
  bottom: -2em;
  left: 45%;
}

.accountButton {
  width: 330px;
}

.buttonText {
  text-align: left;
  left: 11%;
  margin-bottom: 0;
  font-size: 2.35em;
}

.primary-header {
  order: -3;
  font-family: "Brook", Ariel;
  font-size: 2.5em;
}

.button-box {
  display: flex;
  left: 34%;
  margin-top: 3%;
}

.backButton {
  height: 115px;
  width: 115px;
  font-size: 5em;
  margin-bottom: 0;
  margin-right: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.submitButton {
  height: 115px;
  width: 325px;
  font-size: 5em;
}

.unlinkButton {
  background-color: #d63924;
  border: 4px solid #530d03;
  border-radius: 10px;

  width: 150px;
  height: 70px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 10px;
  margin-bottom: 15px;
}

.signInButton {
  background-color: #5bbc2b;
  border: 4px solid #183e09;
  border-radius: 10px;

  width: 150px;
  height: 70px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 10px;
  margin-bottom: 15px;
}

.unlinkButton:hover:enabled,
.signInButton:hover:enabled {
  border-color: white;
  cursor: pointer;
}

.unlinkButton:disabled {
  background-color: #444;
  border: 4px solid #888;
  opacity: .5;
}

.header-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.friends-header {
  color: #d63924;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
}

.friends-message {
  text-align: center;
}

.resulting-platforms {
  background-color: #000000;
  border: 4px solid #121212;
  border-radius: 10px;

  min-width: 20vw;
  min-height: 250px;
  padding: 1em;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.puid {
  margin-bottom: 0.5em;
  font-size: 1.4em;
  margin-left: 1%;
}

/* medium screens */
@media screen and (max-width: 1200px) {
  .platform-box {
    width: 40vw;
  }

  .accountButton {
    width: 30vw;
  }
}

/* small screens */
@media screen and (max-width: 500px) {
  body {
    background: #020916;
    background-image: none;
  }

  .primary-header {
    font-size: 2.5em;
  }

  .platform-box {
    width: 66vw;
    min-height: 100px;
  }

  .accountButton {
    width: 50vw;
    height: 50px;
    font-size: 0.75em;
  }
}
</style>
